import styled from 'styled-components'

import {
  darkGray,
  lightBackground,
  lightSalmon,
  salmon,
} from '../../../../styles/themeColors'

export const ModalTiersContainer = styled.div`
  text-align: center;
  width: 100%;
  & * {
    font-size: 13px;
  }
  & .tier-item-editing {
    display: inline-block;
    margin: 5px !important;
    min-width: 125px;
  }
  & .addingNewTier {
    display: block;
    margin: 0 auto !important;
  }
  & > button {
    display: inline-block;
    font-weight: 600;
    margin: 5px !important;
    min-width: 125px;
    &.float-right {
      display: block;
      float: none !important;
      margin: 10px auto 0 auto !important;
    }
    &.btn.btn-outline-primary {
      background-color: ${lightSalmon};
      border-color: ${lightSalmon};
      color: ${darkGray};
    }
  }
`

export const GuestListTitle = styled.span`
  color: ${salmon};
  font-size: 35px;
  left: 0;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 100%;
  z-index: 1;
  @media (max-width: 600px) {
    position: relative;
    text-align: left;
    top: -5px;
  }
`

export const BookingsContainer = styled.div`
  width: 100%;
  @media (max-width: 991px) {
    align-items: flex-start;
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    overflow-x: auto;
    padding: 0 0 7px 0;
    & .b-row:nth-child(even) {
      background-color: ${lightBackground};
    }
  }
  @media (max-width: 500px) {
    &.single {
      justify-content: center;
    }
  }

  // row
  & .b-row {
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    padding: 10px 2rem;
    width: 100%;
    &.inner {
      padding: 10px;
      width: 90%;
      &:last-child {
        border-bottom: none;
      }
    }
    & img {
      height: 180px;
      object-fit: cover;
      width: 250px;
    }
    @media (max-width: 1200px) {
      width: 96%;
    }
    @media (max-width: 991px) {
      border-bottom: none;
      justify-content: center;
      padding: 10px;
      & .CheckInOut {
        display: inline-block;
        max-width: 184px;
      }
      & .offering-data {
        order: 2;
        padding-bottom: 15px;
      }
      & img {
        margin-bottom: 10px;
        height: 150px;
        order: 1;
        min-width: 220px;
        width: 100%;
      }
    }
  }

  & .more-details {
    border-bottom: 1px solid #dee2e6;
    margin: 0 auto;
    height: auto; /* A large enough number to make sure the content expands */
    overflow: hidden;
    padding: 1rem 0;
    transition: all 0.2s linear !important;
    width: 100%;
    &.collapsed {
      border-bottom: none;
      height: 0;
      padding: 0;
      transition: all 0.5s linear;
    }
  }
`

export const RsvpManagerContainer = styled.div`
  width: 100%;
`

export const FilterByPackagesContainer = styled.div`
  width: 100%;
  & button {
    display: inline-block;
    margin: 0 0.4rem 0.25rem 0;
    width: fit-content;
  }
`
