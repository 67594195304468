import React from 'react'

import { formatTime } from '../common'
import { PackageAddons } from '../types'

export const PackageCustomizations: React.FC<{
  customizations: PackageAddons[]
}> = ({ customizations }) => {
  if (customizations.length <= 0) return <></>
  return (
    <div className="w-100 py-2 px-3 px-lg-0">
      <p className="mb-1 customizations-title">Schedule</p>
      <ul>
        {customizations.map(el => (
          <li key={el.id}>
            {el.offeringAddOn.label} - {formatTime(el.time)}
          </li>
        ))}
      </ul>
    </div>
  )
}
