import React from 'react'
import { Redirect } from 'react-router-dom'

import { useCurrentUser } from '../common'
import { VENDOR } from '../common/constants'

import SocialPanel from './SocialPanel'
import { VendorPanel } from './VendorPanel'

export const Panel: React.FC = () => {
  const { isAuthenticated, role } = useCurrentUser()

  if (!isAuthenticated) return <Redirect to="/" />

  if (role === VENDOR) {
    return <VendorPanel />
  }

  return <SocialPanel />
}
