import gql from 'graphql-tag'

import { IMAGE_FIELDS_FRAG, PACKAGE_ADDONS_FIELDS } from './general'

// EC = Event Component
export const RSVP_EC = gql`
  fragment EventComponentFields on EventComponent {
    id
    date
    duration
    alias
    status
    capacity
    eventId
    customTexts
    booking {
      id
      status
    }
    main
    packageCustomization {
      ...PackageAddonsFields
    }
    tiers {
      id
      name
    }
    bookingCount
    guestPays
    offering {
      __typename
      ... on PackageOffering {
        name
        intro
        description
        mainImage {
          ...ImageFields
        }
        images {
          ...ImageFields
        }
        provider {
          timeToAirport
          airportCode
          coord
          id
          url
          address
          location
          name
          mainImage {
            ...ImageFields
          }
          images {
            ...ImageFields
          }
        }
      }
      ... on RoomOffering {
        name
        description
        mainImage {
          ...ImageFields
        }
        images {
          ...ImageFields
        }
        price
        room {
          beds
          occupancy
          size
          basic
        }
        provider {
          timeToAirport
          airportCode
          coord
          id
          url
          address
          location
          name
          mainImage {
            ...ImageFields
          }
          images {
            ...ImageFields
          }
        }
      }
    }
  }
  ${IMAGE_FIELDS_FRAG}
  ${PACKAGE_ADDONS_FIELDS}
`

export const OFFERING_ADDONS_FIELDS = gql`
  fragment OfferingAddonsFields on OfferingAddOns {
    id
    frequency
    label
    price
    mandatory
    quantity
    metadata
    type
    offeringAddOnOptions {
      id
      description
      label
      price
      quantity
      offeringAddOnId
    }
  }
`

export const GUEST = gql`
  query guestMe {
    me {
      ... on Guest {
        event {
          id
          name
          mainImage
          description
          video
          brideFirstName
          brideLastName
          groomFirstName
          groomLastName
          invitationTemplate {
            template
          }
          host {
            firstName
            lastName
          }
        }
        status
        id
        email
        firstName
        lastName
        kidsAllowed
        plusOne
        bookedComponents
        eventComponents {
          ...EventComponentFields
        }
        mainComponent {
          ...EventComponentFields
        }
      }
    }
  }
  ${RSVP_EC}
`

export const FLIGHT_DATA = gql`
  fragment flightData on FlightInformation {
    adults
    arrivalAirline
    arrivalAirport
    arrivalDatetime
    arrivalFlight
    arrivalLocation
    departureAirline
    departureAirport
    departureDatetime
    departureFlight
    departureLocation
    guestNames
    id
    kids
    propertyName
  }
`

export const BOOKING_ADDONS_FIELDS = gql`
  fragment BookingAddonsFields on BookingAddOn {
    id
    offeringAddOnOption {
      id
      label
      price
      description
      quantity
      offeringAddOnId
    }
    offeringAddOn {
      id
      frequency
      label
      mandatory
      taxable
      format
      price
      type
      metadata
    }
    bookingGuest {
      firstName
      lastName
      phone
      email
      plusone
      guest {
        id
        flightInformation {
          ...flightData
        }
      }
    }
  }
  ${FLIGHT_DATA}
`

export const PROVIDER_FIELDS_FOR_BOOKING = gql`
  fragment ProviderFields on Provider {
    bookAllRequired
    airportCode
    location
    id
    address
    url
    name
    accomodationTaxRate
    venueTaxRate
    cancellationPolicy
  }
`

export const SEND_INVITATION = gql`
  mutation sendInvitation($id: ID!) {
    sendInvitation(id: $id) {
      result {
        id
        status
      }
      errors {
        key
        message
      }
    }
  }
`

export const SEND_PRE_INVITATION = gql`
  mutation sendPreInvitation($id: ID!) {
    sendPreInvitation(id: $id) {
      result {
        id
        status
        preInvitationSent
      }
      errors {
        key
        message
      }
    }
  }
`

export const SEND_REMINDER = gql`
  mutation sendReminder($guestId: ID!, $template: String!) {
    sendReminder(guestId: $guestId, template: $template) {
      result {
        id
        status
        reminderSentAt
      }
      errors {
        key
        message
      }
    }
  }
`

export const GUEST_EVENT = gql`
  mutation triggerGuestEvent($guestId: ID!, $event: GuestEvent!) {
    triggerGuestEvent(guestId: $guestId, event: $event) {
      result {
        id
        status
      }
      errors {
        key
        message
      }
    }
  }
`

export const UPDATE_MY_INVITATION = gql`
  mutation updateMyInvitation($guestId: ID!, $event: GuestEvent!) {
    updateMyInvitation(guestId: $guestId, event: $event) {
      result {
        id
        status
      }
      errors {
        key
        message
      }
    }
  }
`

export const RSVP_PREVIEW_DATA = gql`
  query getMyEventInfoRSVP($id: ID!) {
    me {
      id
      ... on Customer {
        myEvents(id: $id) {
          defaultKidsAllowed
          defaultPlusOne
          id
          name
          mainImage
          description
          components {
            ...EventComponentFields
          }
          video
          brideFirstName
          brideLastName
          groomFirstName
          groomLastName
          invitationTemplate {
            template
          }
        }
      }
    }
  }
  ${RSVP_EC}
`

export const CREATE_BOOKING = gql`
  mutation createBooking($eventComponentId: ID!) {
    createBooking(eventComponentId: $eventComponentId) {
      result {
        id
        status
      }
      errors {
        key
        message
      }
    }
  }
`

export const CREATE_BOOKING_FOR_GUEST = gql`
  mutation createBookingForGuest($eventComponentId: ID!, $guestId: ID!) {
    createBookingForGuest(
      eventComponentId: $eventComponentId
      guestId: $guestId
    ) {
      result {
        id
        status
      }
      errors {
        key
        message
      }
    }
  }
`

export const GET_BOOKING_DATA = gql`
  query getBookingInfo($id: ID!, $userId: ID!) {
    me {
      ... on Customer {
        events {
          id
          name
        }
        myEvents {
          id
          name
        }
      }
    }
    booking(id: $id, userId: $userId) {
      id
      status
      availability
      date
      duration
      guest {
        id
        plusOne
        plusOneCount
        firstName
        lastName
        email
        phone
        flightInformation {
          ...flightData
        }
      }
      guestPrice {
        basePrice
        tax
        total
        offeringPrice
        duration
      }
      bookingAddOn {
        ...BookingAddonsFields
      }
      eventComponent {
        id
        date
        duration
        capacity
        guestPays
        eventId
        alias
        main
        deadline
        softDeadline
        items {
          deleted
          master
          price
        }
        event {
          id
          name
        }
        customTexts
        packageCustomization {
          ...PackageAddonsFields
        }
        bookingCount
        offering {
          __typename
          ... on PackageOffering {
            name
            intro
            description
            mainImage {
              ...ImageFields
            }
            images {
              ...ImageFields
            }
            provider {
              ...ProviderFields
            }
            price
            addOns {
              ...OfferingAddonsFields
            }
            package {
              basic
            }
          }
          ... on RoomOffering {
            name
            id
            description
            mainImage {
              ...ImageFields
            }
            images {
              ...ImageFields
            }
            provider {
              ...ProviderFields
            }
            price
            room {
              beds
              occupancy
              size
              basic
            }
            addOns {
              ...OfferingAddonsFields
            }
          }
        }
      }
    }
  }
  ${IMAGE_FIELDS_FRAG}
  ${PACKAGE_ADDONS_FIELDS}
  ${FLIGHT_DATA}
  ${OFFERING_ADDONS_FIELDS}
  ${BOOKING_ADDONS_FIELDS}
  ${PROVIDER_FIELDS_FOR_BOOKING}
`

export const CUSTOMIZE_BOOKING_DURATION = gql`
  mutation customizeBookingDuration(
    $bookingId: ID!
    $date: NaiveDateTime
    $duration: Int
    $addonNightsAfterId: ID
    $optionNightsAfterId: ID
    $addonNightsBeforeId: ID
    $optionNightsBeforeId: ID
  ) {
    customizeBookingDuration(
      bookingId: $bookingId
      date: $date
      duration: $duration
      addonNightsAfterId: $addonNightsAfterId
      optionNightsAfterId: $optionNightsAfterId
      addonNightsBeforeId: $addonNightsBeforeId
      optionNightsBeforeId: $optionNightsBeforeId
    ) {
      result {
        id
        status
        date
        duration
        bookingAddOn {
          id
          offeringAddOnOption {
            id
            label
            price
            description
            quantity
          }
          offeringAddOn {
            id
            frequency
            label
            mandatory
            taxable
            format
            price
            type
            metadata
          }
          bookingGuest {
            firstName
            lastName
            phone
            email
            guest {
              id
            }
          }
        }
      }
      errors {
        key
        message
      }
    }
  }
`

export const VALIDATE_GUEST_PAYMENT = gql`
  mutation validateGuestPayment($paymentIntent: String!) {
    validateGuestPayment(paymentIntent: $paymentIntent) {
      result {
        paymentStatus
        booking {
          id
          status
        }
      }
      errors {
        key
        message
      }
    }
  }
`

export const INITIATE_GUEST_PAYMENT = gql`
  mutation initiateGuestPayment($bookingId: ID!) {
    initiateGuestPayment(bookingId: $bookingId) {
      result {
        id
        clientSecret
        amount
      }
      errors {
        key
        message
      }
    }
  }
`

export const CONFIRM_BOOKING = gql`
  mutation confirmBooking(
    $bookingId: ID!
    $extraNights: ExtraNightsAddon
    $freeGuests: [GuestBookingAddon]
    $paidGuests: [GuestBookingAddon]
    $addonsToDelete: [ID!]
  ) {
    confirmBooking(
      bookingId: $bookingId
      freeGuests: $freeGuests
      paidGuests: $paidGuests
      extraNights: $extraNights
      addonsToDelete: $addonsToDelete
    ) {
      result {
        id
        status
      }
      errors {
        key
        message
      }
    }
  }
`

export const EDIT_BOOKING = gql`
  mutation editBooking(
    $bookingId: ID!
    $freeGuests: [GuestBookingAddon]
    $addonsToDelete: [ID!]
  ) {
    editBooking(
      bookingId: $bookingId
      freeGuests: $freeGuests
      addonsToDelete: $addonsToDelete
    ) {
      result {
        id
        status
      }
      errors {
        key
        message
      }
    }
  }
`

export const DECLINE_BOOKING = gql`
  mutation declineBooking($id: ID!, $guestId: ID, $offeringAddOnId: ID) {
    declineBooking(
      id: $id
      guestId: $guestId
      offeringAddOnId: $offeringAddOnId
    ) {
      result {
        id
        status
      }
      errors {
        key
        message
      }
    }
  }
`

export const CANCEL_BOOKING = gql`
  mutation cancelBooking($id: ID!) {
    cancelBooking(id: $id) {
      result {
        id
        status
      }
      errors {
        key
        message
      }
    }
  }
`

export const GET_INVITATION_DATA = gql`
  query getInviatationData($eventId: ID!) {
    me {
      id
      ... on Customer {
        myEvents {
          id
        }
        invitations {
          eventId
          guestId
          status
          kidsAllowed
          plusOne
        }
        invitation(eventId: $eventId) {
          id
          name
          description
          mainImage
          video
          brideFirstName
          brideLastName
          groomFirstName
          groomLastName
          invitationTemplate {
            template
          }
          host {
            firstName
            lastName
          }
          components {
            ...EventComponentFields
          }
        }
      }
    }
  }
  ${RSVP_EC}
`

export const ADD_BOOKING_ADDON = gql`
  mutation addAddonToBooking(
    $bookingId: ID!
    $offeringAddOnId: ID!
    $offeringAddOnOptionId: ID
    $guestBookingInput: GuestBookingInput
  ) {
    addAddonToBooking(
      bookingId: $bookingId
      offeringAddOnId: $offeringAddOnId
      offeringAddOnOptionId: $offeringAddOnOptionId
      guestBookingInput: $guestBookingInput
    ) {
      result {
        id
        offeringAddOnOption {
          id
          label
          price
          description
          quantity
        }
        offeringAddOn {
          id
          frequency
          label
          mandatory
          price
          taxable
          format
          type
          metadata
        }
        bookingGuest {
          firstName
          lastName
          phone
          email
          plusone
          guest {
            id
          }
        }
      }
      errors {
        key
        message
      }
    }
  }
`

export const REMOVE_BOOKING_ADDON = gql`
  mutation removeAddonFromBooking($bookingId: ID!, $bookingAddonId: ID!) {
    removeAddonFromBooking(
      bookingId: $bookingId
      bookingAddonId: $bookingAddonId
    ) {
      result {
        id
      }
      errors {
        key
        message
      }
    }
  }
`

export const GET_BOOKINGS_BY_IDS = gql`
  query getBookingsByIds($ids: [ID!]!) {
    bookings(ids: $ids) {
      id
      status
      date
      duration
      guestPrice {
        total
      }
      bookingAddOn {
        id
        bookingGuest {
          email
          firstName
          lastName
          phone
          plusone
        }
        offeringAddOn {
          metadata
        }
        offeringAddOnOption {
          quantity
        }
      }
      eventComponent {
        id
        date
        alias
        duration
        guestPays
        __typename
        offering {
          ... on RoomOffering {
            id
            name
            mainImage {
              ...ImageFields
            }
            provider {
              name
            }
          }
          ... on PackageOffering {
            id
            name
            mainImage {
              ...ImageFields
            }
            provider {
              name
            }
          }
        }
      }
    }
  }
  ${IMAGE_FIELDS_FRAG}
`

export const ADD_ALL_ADDONS_TO_BOOKING = gql`
  mutation addAllAddonsToBooking(
    $bookingId: ID!
    $extraNights: ExtraNightsAddon
    $freeGuests: [GuestBookingAddon]
    $paidGuests: [GuestBookingAddon]
    $addonsToDelete: [ID!]
  ) {
    addAllAddonsToBooking(
      bookingId: $bookingId
      freeGuests: $freeGuests
      paidGuests: $paidGuests
      extraNights: $extraNights
      addonsToDelete: $addonsToDelete
    ) {
      result {
        id
      }
      errors {
        key
        message
      }
    }
  }
`

export const ADD_FLIGHT_INFORMATION = gql`
  mutation addFlightInformation(
    $guestId: ID!
    $input: FlightInformationInput!
  ) {
    addFlightInformation(guestId: $guestId, input: $input) {
      result {
        ...flightData
      }
      errors {
        key
        message
      }
    }
  }
  ${FLIGHT_DATA}
`

export const UPDATE_FLIGHT_INFORMATION = gql`
  mutation updateFlightInformation(
    $guestId: ID!
    $input: FlightInformationInput!
  ) {
    updateFlightInformation(guestId: $guestId, input: $input) {
      result {
        ...flightData
      }
      errors {
        key
        message
      }
    }
  }
  ${FLIGHT_DATA}
`

export const MANAGE_GUEST_BOOKING = gql`
  mutation manageGuestBooking(
    $id: ID!
    $eventId: ID!
    $action: HostBookingAction!
  ) {
    manageGuestBooking(id: $id, eventId: $eventId, action: $action) {
      result {
        id
        status
      }
      errors {
        key
        message
      }
    }
  }
`
