import React, { useState } from 'react'
import get from 'lodash/fp/get'
import set from 'lodash/fp/set'
import pull from 'lodash/fp/pull'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { FaPlus, FaCog } from 'react-icons/fa'
import { useMutation } from '@apollo/react-hooks'

import { MyEvent, Maybe, GetMyEventQuery } from '../../types'
import { useForm } from '../../common/alterForms'
import { CREATE_TIER, GET_MY_EVENT } from '../../graphql'

import ItemEditing from './ItemEditing'
import Selector from './Selector'

interface ITiersContainer {
  event: MyEvent
  selection: string[]
  onSelect: (selection: string[]) => void
}

const Container: React.FC<ITiersContainer> = ({
  event,
  selection,
  onSelect,
}) => {
  const [editing, setEditing] = useState(false)

  const initModel = { name: '' }
  const { model, errors, handleFieldChange, reset, pushError } =
    useForm(initModel)
  const [createTierMutation, { loading }] = useMutation(CREATE_TIER, {
    update(cache, { data: { createTier } }) {
      if (createTier.errors.length === 0) {
        const variables = { id: event.id, tierIds: [] }
        const cached: Maybe<GetMyEventQuery> = cache.readQuery({
          query: GET_MY_EVENT,
          variables,
        })
        if (cached) {
          const tiers = get('me.myEvents[0].tiers', cached) || []
          cache.writeQuery({
            query: GET_MY_EVENT,
            data: set(
              'me.myEvents[0].tiers',
              [createTier.result, ...tiers],
              cached
            ),
            variables,
          })
        }
      }
    },
  })

  const updateSelection = (id: string): void => {
    onSelect(pull(id, selection))
  }

  const addTier = () => {
    if (!loading) {
      const variables = {
        input: model,
        eventId: event.id,
      }
      createTierMutation({ variables }).then(({ data: { createTier } }) => {
        if (createTier.errors.length === 0) {
          reset(initModel)
        } else {
          pushError('whatever', 'name')
        }
      })
    }
  }

  return (
    <>
      <div data-cy="sublists">
        {editing && (
          <p className="fz-12 tc-lgray ">
            Segment your group communication by priority, event, accommodations
            or special role.
          </p>
        )}

        {!editing && (
          <Selector
            tiers={event.tiers}
            selection={selection}
            onSelect={onSelect}
          />
        )}

        {!editing && event.tiers.length === 0 && (
          <span className="text-muted">
            You have no sub-lists, click on gear button to add some.
          </span>
        )}

        {editing && (
          <div className="addingNewTier mr-2 d-inline-block">
            <InputGroup className="mb-2">
              <FormControl
                data-cy="new-sublist"
                className="border-dark"
                style={{ borderRadius: '19px 0px 0px 19px' }}
                value={model.name || ''}
                onChange={({ currentTarget }) =>
                  handleFieldChange('name', currentTarget.value)
                }
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    addTier()
                  }
                }}
                isInvalid={!!errors.name}
                placeholder="Type sub-list"
              />
              <InputGroup.Append>
                <Button
                  data-cy="new-sublist-btn"
                  className="bg-dark"
                  variant="outline-dark"
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    e.preventDefault()
                    addTier()
                  }}
                >
                  <FaPlus className="mb-1" color="white" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
        )}

        {editing &&
          event.tiers.map(tier => (
            <ItemEditing
              className="mr-1 mb-1"
              tier={tier}
              key={tier.id}
              event={event}
              updateSelection={updateSelection}
            />
          ))}
      </div>
      <div className="clearfix">
        <Button
          variant={editing ? 'primary' : 'outline-dark'}
          className="float-right py-1 fwb"
          onClick={() => setEditing(!editing)}
        >
          <FaCog className="mr-1 svg-top1" />
          {editing ? 'Exit edit' : 'Edit lists'}
        </Button>
      </div>
    </>
  )
}
export default Container
