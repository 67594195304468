import React from 'react'
import getOr from 'lodash/fp/getOr'
import Dropdown from 'react-bootstrap/Dropdown'
import { MdDone } from 'react-icons/md'
import { RiCake2Line } from 'react-icons/ri'
import { useQuery } from '@apollo/react-hooks'
import { useLocation, Link } from 'react-router-dom'

import { GET_ALL_EVENTS } from '../../graphql'
import { MyEvent } from '../../types'

import { UserOptionsNav } from './style'

const MyEvents: React.FC = () => {
  const location = useLocation()
  const { data } = useQuery(GET_ALL_EVENTS, {
    fetchPolicy: 'cache-and-network',
  })
  const myEvents: MyEvent[] = getOr([], 'me.myEvents', data)
  const activeEvent =
    myEvents.length > 0
      ? myEvents.find(
          (e: MyEvent) => location.pathname.indexOf(`/event/${e.id}`) === 0
        )
      : null

  if (myEvents.length <= 0) return <></>
  return (
    <UserOptionsNav>
      <Dropdown id="event-dropdown-navbar">
        <Dropdown.Toggle variant="outline-primary" className="line-1">
          <RiCake2Line size={22} className="mr-1" />
          {activeEvent?.name || 'Manage Events'}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {myEvents.map((event: MyEvent) => (
            <Dropdown.Item
              key={event.id}
              as={Link}
              to={`/event/${event.id}/settings`}
            >
              {event.name}
              {activeEvent?.id === event.id && (
                <MdDone className="ml-1 svg-top1" />
              )}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </UserOptionsNav>
  )
}

export default MyEvents
