import React, { Suspense, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import getOr from 'lodash/fp/getOr'
import flow from 'lodash/fp/flow'
import filter from 'lodash/fp/filter'
import reduce from 'lodash/fp/reduce'
import { Helmet } from 'react-helmet'
import { MdClear } from 'react-icons/md'
import { useQuery } from '@apollo/react-hooks'
import { useParams, Link } from 'react-router-dom'

import bannerAddGuestRooms from '../../../assets/images/bannerAddGuestRooms.jpg'
import bannerAddPackage from '../../../assets/images/bannerAddPackage.jpg'
import { darkGray } from '../../../styles/themeColors'
import { EventComponent, Tier, EventComponentStatus } from '../../../types'
import { SimpleLoader, NegotiationsPreview } from '../../../components'
import { offeringTypes } from '../../../common/constants'
import { useSetMainComponent } from '../../Rsvp/components'
import {
  GET_EVENT_COMPONENTS,
  GET_NEGOTIATIONS_PREVIEW,
} from '../../../graphql'

import { NoBookingBanner } from './style'
import { EventBuilder } from './EventBuilder'

const BannersModule = React.lazy(
  () => import('../../../components/BannersModule/BannersModule')
)

const getTextAndComponentCounting = (components: EventComponent[]) => {
  const response = {
    text: 'Add More Guest Rooms',
    count: { packages: 0, rooms: 0, hasMainPackage: false, mainPackageId: '' },
  }

  if (components.length <= 0) {
    response.text = 'Add Venues'
    // to avoid unnecessary processing after this point
    return response
  }

  const count = reduce(
    (accum, el: EventComponent) => {
      const accumulator = accum
      const type = el.offering.__typename
      const { packageOffering, roomOffering } = offeringTypes

      if (!accum.hasMainPackage && el.main) {
        accumulator.hasMainPackage = true
      }
      if (accum.mainPackageId === '' && type === packageOffering) {
        // get the firt package id to be used as main package if no main package is set
        accumulator.mainPackageId = el.id
      }

      if (type === roomOffering) {
        return {
          ...accumulator,
          packages: accum.packages,
          rooms: accum.rooms + 1,
        }
      }
      return {
        ...accumulator,
        packages: accum.packages + 1,
        rooms: accum.rooms,
      }
    },
    { packages: 0, rooms: 0, hasMainPackage: false, mainPackageId: '' },
    components
  )
  response.count = count

  const { packages, rooms } = count
  if (packages >= 1 && packages <= 3) response.text = 'Add More Venues'
  if (packages >= 4 && rooms <= 0) response.text = 'Add Guest Rooms'

  return response
}

export const EventBuilderPage: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>()
  const { data, loading } = useQuery(GET_EVENT_COMPONENTS, {
    variables: { id: eventId },
    fetchPolicy: 'cache-and-network',
  })
  const [setMain] = useSetMainComponent()
  const [manageBanner, setManageBanners] = useState({
    addGuestRoom: sessionStorage.getItem('addGuestRoom'),
    addPackage: sessionStorage.getItem('addPackage'),
  })

  const StatesToRemove = [
    EventComponentStatus.Rejected,
    EventComponentStatus.Cancelled,
    EventComponentStatus.Deleted,
  ]

  const components: EventComponent[] = flow(
    getOr([], 'me.myEvents[0].components'),
    filter((el: EventComponent) => !StatesToRemove.includes(el.status))
  )(data)

  const tiers: Tier[] = getOr([], 'me.myEvents[0].tiers', data)
  const { text, count } = getTextAndComponentCounting(components)

  if (!count.hasMainPackage && count.mainPackageId !== '') {
    // to ensure that there is always a main package
    setMain(count.mainPackageId)
  }

  const closeBanner = (banner: string) => {
    setManageBanners({
      ...manageBanner,
      [banner]: 'hide',
    })
    sessionStorage.setItem(banner, 'hide')
  }

  const getNoBookingBanner = (type: string, url = '/marketplace') => (
    <NoBookingBanner className="shadow-sm mb-3">
      <MdClear
        size={35}
        color={darkGray}
        className="close-banner cursor"
        onClick={() => closeBanner(type)}
      />
      <Link to={url}>
        <img
          src={type === 'addGuestRoom' ? bannerAddGuestRooms : bannerAddPackage}
          alt={type}
          className="no-booking-banner"
        />
      </Link>
    </NoBookingBanner>
  )

  if (components.length <= 0 && loading) {
    return <div className="text-center p-5 w-100">loading...</div>
  } else if (components.length <= 0 && !loading) {
    return (
      <Container>
        <Helmet>
          <title>Kiss & Tell - Event Builder</title>
          <meta
            name="description"
            content="Build your event by adding packages, guest rooms, and more."
          />
        </Helmet>
        <Row noGutters id="event-builder-section" className="pt-4">
          <Col xs={12} md={{ span: 10, offset: 1 }}>
            <div className="text-center p-5 w-100 fz-18">
              No components to display
            </div>
            {!manageBanner.addPackage && getNoBookingBanner('addPackage')}
            {!manageBanner.addGuestRoom && getNoBookingBanner('addGuestRoom')}
          </Col>
        </Row>
      </Container>
    )
  }

  const defaultBannerUrl = `/locations?what=${components[0].offering.provider.name}`
  return (
    <Container className="pt-3">
      <Helmet>
        <title>Kiss & Tell - Event Builder</title>
        <meta
          name="description"
          content="Build your event by adding packages, guest rooms, and more."
        />
      </Helmet>
      <Suspense fallback={<SimpleLoader />}>
        {/** In this view the user will always have events, so it's not necessary to use a query */}
        <BannersModule myEvents={1} text={text} />
      </Suspense>

      <Row noGutters id="event-builder-section" className="pt-2">
        <Col xs={12} md={2}>
          <NegotiationsPreview
            variables={{ eventId }}
            QUERY={GET_NEGOTIATIONS_PREVIEW}
            objectPath="me.myEvents[0].negotiations"
            url="/planning"
          />
        </Col>
        <Col xs={12} md={10}>
          <EventBuilder
            eventTiers={tiers}
            eventId={eventId}
            eventComponents={components}
          />

          <br />
          {!manageBanner.addPackage &&
            getNoBookingBanner('addPackage', defaultBannerUrl)}
          {!manageBanner.addGuestRoom &&
            getNoBookingBanner('addGuestRoom', defaultBannerUrl)}
        </Col>
      </Row>
    </Container>
  )
}
