// since it is a copy I am reusing these styles
import React, { lazy, Suspense } from 'react'
import { Link } from 'react-router-dom'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import { Helmet } from 'react-helmet'

import Hero from '../../assets/images/Landingpages/saraandjeanhero.png'
import Collage from '../../assets/images/Landingpages/saraandjeancollage.png'
import { SmallLoader, Wave } from '../../components'
import { IframeYoutube } from '../../common'
import { pink } from '../../styles/themeColors'
import {
  VerticalProgress,
  ExclusiveTheClearCut,
  PromoVideo,
} from '../TheClearCut/style'

import { HeroSection } from './style'

const MarketingFormSaraAndJean = lazy(
  () => import('./components/MarketingFormSaraAndJean')
)

export const SaraAndJean: React.FC = () => {
  return (
    <section className="public-container public-page">
      <Helmet>
        <title>Kiss & Tell | Sara & Jean Paul</title>
        <meta
          name="description"
          content="Sara & Jean Paul's Wedding Voting Contest"
        />
      </Helmet>
      <HeroSection>
        <h1
          className="text-center text-white font-lf align-self-center pb-md-5 pt-4 p-md-0"
          style={{ fontSize: '100px' }}
        >
          They said yes,
          <br />
          Now help plan!
        </h1>
        <div className="text-center pt-3 pb-5 pb-md-0">
          <img src={Hero} alt="Sara & Jean" className="hero" width={690} />
        </div>
        <span>
          <Wave color="#ffffff" />
        </span>
      </HeroSection>
      <section className="overflow-hidden pb-5">
        <h2 className="text-center pb-4 fwl" style={{ fontSize: '40px' }}>
          Help Sara & Jean Paul <span className="fwsb">Plan Their Wedding</span>
        </h2>

        <ExclusiveTheClearCut>
          <div className="mb-4 mb-md-0">
            <IframeYoutube
              videoIds={[
                'BAd558orbcQ',
                'QL51wP_YSVw',
                'PDpD9kK3bfg',
                'iLzI0Pt4JbI',
                'pf-UGXS329o',
              ]}
              width="268px"
              height="476px"
              type="vertical"
              controls={false}
              playing={false}
              mobileFrame
            />
          </div>
          <div className="align-self-center px-3 px-sm-5 px-lg-4">
            <div className="d-flex align-items-stretch">
              <VerticalProgress>
                <IoMdCheckmarkCircle color="white" size={40} />
                <span className="active">2</span>
                <span>3</span>
                <span>4</span>
                <span>5</span>
              </VerticalProgress>
              <div className="list pl-5 pr-2 fwsb">
                <p className="mb-3">
                  Sign up to access their event on Kiss & Tell
                </p>
                <p className="mb-3">
                  Confirm your email then receive your invitation to Sara & Jean
                  Paul’s Wedding Voting Contest
                </p>
                <p className="mb-3">
                  Log in to Kissandtell.com once a month to vote for their
                  Riviera Maya venue & vendors
                </p>
                <p className="mb-3">
                  Each vote is a chance to win a Riviera Maya vacation
                </p>
                <p className="mb-0">
                  Share this contest on Instagram tagging @doyoukissandtell for
                  a second chance to win
                </p>
              </div>
            </div>
          </div>
        </ExclusiveTheClearCut>
      </section>

      <Wave
        color={pink}
        style={{
          position: 'relative',
          bottom: '-1px',
        }}
      />
      <section style={{ backgroundColor: pink }}>
        <Suspense fallback={<SmallLoader />}>
          <MarketingFormSaraAndJean />
        </Suspense>
      </section>
      <Wave
        color={pink}
        style={{
          transform: 'rotate(180deg)',
          position: 'relative',
          top: '-1px',
        }}
      />

      <div className="pt-5 pb-5 mt-4">
        <PromoVideo>
          <div className="px-3 px-sm-5">
            <IframeYoutube videoId="jAJuzPinbPo" />
          </div>
          <div className="px-3 px-sm-5 align-self-center pb-5 pb-lg-0">
            <h2 className="fwsb mb-4">
              After helping Sara & Jean Paul plan, check us out for your own
              event!
            </h2>
            <p className="m-0 fz-18">
              <Link to="/marketplace" className="fwsb" target="_blank">
                Explore our marketplace
              </Link>{' '}
              of top global destinations and seamlessly tie everything together
              with streamlined group planning, booking, communication and
              logistics for your big day!
            </p>
          </div>
        </PromoVideo>
      </div>

      <div className="bg-salmon-l py-3 py-lg-5 px-3 mt-0 mt-lg-5">
        <h2 className="text-center pb-4 fwl" style={{ fontSize: '36px' }}>
          See you in Riviera Maya!
        </h2>
        <img
          src={Collage}
          alt="Sara & Jean Paul Collage"
          className="d-block mx-auto mw-100"
        />
      </div>
    </section>
  )
}
