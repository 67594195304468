import React, { useState } from 'react'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import toInteger from 'lodash/fp/toInteger'
import Badge from 'react-bootstrap/Badge'
import { MdMoreHoriz } from 'react-icons/md'

import { getImageUrl } from '../../../common'
import { EventComponent } from '../../../types'
import { createOccupancyIcons } from '../../Locations'
import { RoomIcon } from '../../../components/customIcons'
import { SingleRoomCard } from '../../../components/Cards/style'
import { FormatNumber, OfferingModal } from '../../../components'

import { CTAbook } from './CTAbook'

// I am creating a new component instead of using the existing one because that component already has enough logic to add more complexity
// so I prefer to have an extra component but simpler to understand/read
export const RoomCardSelection: React.FC<{ component: EventComponent }> = ({
  component,
}) => {
  const [showModal, setShowModal] = useState(false)

  const room = component.offering
  const { price, mainImage } = room

  const occupancy = flow(get('room.occupancy'), toInteger)(room)
  const size = flow(get('room.size'), toInteger)(room)
  const basic = flow(get('room.basic'), toInteger)(room)
  const availability = component.capacity - component.bookingCount
  return (
    <>
      <OfferingModal
        data={room}
        show={showModal}
        setShowModal={setShowModal}
        optionalCTA={
          <div className="text-center pb-4">
            <CTAbook eventComponent={component} className="px-5 fz-18">
              <RoomIcon color="white" width="25px" height="25px" />
              Select
            </CTAbook>
          </div>
        }
      />
      <SingleRoomCard className="mr-2 mr-lg-3 ml-2 ml-lg-0 mb-3 shadow-sm">
        <div
          className="cover cursor"
          style={{
            backgroundImage: `url("${getImageUrl(mainImage, '400x250')}")`,
          }}
          onClick={() => setShowModal(true)}
        >
          <span className="cursor" onClick={() => setShowModal(true)}>
            <MdMoreHoriz color="white" size={30} />
          </span>
        </div>
        <h1 className="px-3 mb-2 fwb fz-18">{room.name}</h1>
        <div className="px-3 data position-relative">
          <p className="price">
            Price:{' '}
            {price === '0' ? (
              'upon request'
            ) : (
              <>
                <FormatNumber n={price * component.guestPays} /> /nightly rate
              </>
            )}
          </p>
          {occupancy > 0 && (
            <div className="occupancy-cont">
              <p className="occupancy">OCCUPANCY</p>
              <div className="d-flex align-items-center w-100 flex-wrap justify-content-start">
                <div>{createOccupancyIcons(occupancy)} Max</div>
                <div className="px-1">
                  / {createOccupancyIcons(basic)} Included
                </div>
              </div>
            </div>
          )}
          {size > 0 && <p className="size">Size: {size} sq. ft.</p>}
        </div>
        <div className="px-3 pb-3">
          {/** in case availability is less than 0 */}
          {availability > 0 ? `${availability} Available` : '0 Available'}
        </div>
        <div className="px-3 pb-2">
          {component?.tiers.map(tier => (
            <Badge pill key={tier.id} className="mr-1 py-1 badge-gray fz-13">
              {tier.name}
            </Badge>
          ))}
        </div>
        <div className="px-3 pb-3 text-right">
          <CTAbook eventComponent={component} className="px-3 py-1">
            <RoomIcon color="white" width="25px" height="25px" />
            Select
          </CTAbook>
        </div>
      </SingleRoomCard>
    </>
  )
}
