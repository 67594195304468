import React from 'react'
import Swal from 'sweetalert2'
import Table from 'react-bootstrap/Table'
import toNumber from 'lodash/fp/toNumber'
import filter from 'lodash/fp/filter'
import sumBy from 'lodash/fp/sumBy'
import { MdDelete, MdCancel, MdCheckCircle } from 'react-icons/md'
import { useMutation } from '@apollo/react-hooks'

import { FormatNumber } from '../../../../components'
import { salmon } from '../../../../styles/themeColors'
import { VENDOR, CUSTOMER, offeringTypes } from '../../../../common/constants'
import {
  errorAlert,
  confirmAlert,
  loaderAlert,
  useCurrentUser,
  getCheckInOut,
  orderPackageAddons,
} from '../../../../common'
import {
  DELETE_NEGOTIATION_ITEM,
  TRIGGER_EVENT_COMPONENT_EVENT,
} from '../../../../graphql'
import {
  Quote,
  NegotiationItem,
  EventComponentStatus,
  EventComponentEvent,
} from '../../../../types'

import {
  AddNegotiationItem,
  StartPayment,
  CustomizePackage,
  RenderCustomization,
} from '.'

const NegotiationQuote: React.FC<{ quote: Quote }> = ({ quote }) => {
  const { role } = useCurrentUser()
  const { Ready, Negotiating } = EventComponentStatus
  const [deleteItem, { loading }] = useMutation(DELETE_NEGOTIATION_ITEM)
  const [handleCancelEventComponent, { loading: loadingCancel }] = useMutation(
    TRIGGER_EVENT_COMPONENT_EVENT
  )

  const handleDeleteItem = (negotiationItemId: string) => {
    if (loading || role !== VENDOR) return
    confirmAlert({
      html: 'Are you sure you want to delete this item?',
      icon: 'warning',
    }).then(({ value }) => {
      if (value === true) {
        loaderAlert({ html: 'deleting...' })
        const variables = { negotiationItemId }
        deleteItem({ variables }).then(({ data }) => {
          if (data.deleteNegotiationItem.errors.length > 0) {
            errorAlert(
              data.deleteNegotiationItem.errors,
              'it was not possible to delete this item'
            )
          }
          Swal.close()
        })
      }
    })
  }

  const cancelBooking = (id: string) => {
    if (loadingCancel || role !== CUSTOMER) return
    confirmAlert({
      html: 'Are you sure you want to cancel this reservation?',
    }).then(response => {
      if (response.value) {
        const variables = { id, event: EventComponentEvent.Cancel }
        handleCancelEventComponent({ variables }).then(({ data }) => {
          if (data.triggerEventComponentEvent.errors.length > 0) {
            errorAlert(
              data.triggerEventComponentEvent.errors,
              'There was a problem canceling this item'
            )
          }
        })
      }
    })
  }

  const DeleteItemIcon = (quoteStatus: string, item: NegotiationItem) => {
    if (
      role !== VENDOR ||
      quoteStatus !== Negotiating ||
      item.master === true
    ) {
      return <></>
    }
    return (
      <MdDelete
        color={salmon}
        size={18}
        title="Delete"
        className="cursor svg-top1 mr-1"
        onClick={() => handleDeleteItem(item.id)}
      />
    )
  }

  const filteredItems: NegotiationItem[] = filter(o => !o.deleted, quote.items)
  const {
    id: eventComponentId,
    status,
    date,
    duration,
    packageCustomization,
    offering: { __typename: offeringType, addOns },
  } = quote.eventComponent
  const pc = orderPackageAddons(packageCustomization)

  const total = sumBy(i => toNumber(i.price), filteredItems) || 0

  const CancelQuoteIcon = (quoteStatus: string) => {
    if (role !== CUSTOMER || quoteStatus !== Negotiating) {
      return <></>
    }
    return (
      <button
        type="button"
        className="small-btn-outline-gray"
        onClick={() => cancelBooking(eventComponentId)}
      >
        <MdCancel size={12} /> cancel booking
      </button>
    )
  }

  const { packageOffering } = offeringTypes
  return (
    <>
      <span className="fz-11 pl-2">
        Date: {getCheckInOut({ date, dateFormat: 'MMM DD, YYYY', duration })}
      </span>
      <Table hover className="mb-4">
        <thead>
          <tr>
            <td colSpan={2}>
              {role === VENDOR && offeringType === packageOffering && (
                <CustomizePackage
                  customization={pc}
                  availableAddons={addOns}
                  eventComponentId={eventComponentId}
                />
              )}
              {role === VENDOR && status === Negotiating && (
                <>
                  <AddNegotiationItem
                    eventComponentId={eventComponentId}
                    discountLimit={total}
                  />
                  <StartPayment eventComponentId={eventComponentId} />
                </>
              )}
              {CancelQuoteIcon(status)}
              {status === Ready && (
                <span className="float-left fz-12">
                  <MdCheckCircle size={16} color={salmon} /> ready to pay
                </span>
              )}
            </td>
          </tr>
        </thead>
        <tbody>
          {filteredItems.map(item => (
            <React.Fragment key={item.id}>
              <tr>
                <td>
                  {DeleteItemIcon(status, item)}
                  {item.title}
                </td>
                <td>
                  <FormatNumber n={item.price} />
                </td>
              </tr>
              {item.master && offeringType === packageOffering && (
                <tr>
                  <td colSpan={2} className="pb-0 pt-1">
                    <RenderCustomization customization={pc} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>Total</td>
            <td>
              <FormatNumber n={total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  )
}

export default React.memo(NegotiationQuote)
