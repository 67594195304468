import React from 'react'
import moment, { Moment } from 'moment-timezone'

export const fromNow = (datetime: string): string => {
  if (!datetime) return ''
  /**
   * example:
   * datetime -> 2019-10-09T04:13:58
   * return -> 3 days ago
   */
  const parsed = moment.tz(datetime, 'Greenwich')
  return moment(parsed).fromNow()
}

export const getStandardDate = ({
  date,
  timeZone,
  format = 'DD MMMM YYYY - hh:mm:ss A',
}: {
  format?: string
  date: string
  timeZone?: string
}): string => {
  /**
   * returns the date based on the user's time zone
   * example:
   * datetime -> 2020-09-21T19:00:15 (Greenwich)
   * return -> 21 September 2020 - 01:00:15 PM (America/El_Salvador)
   */
  const userTimeZone = timeZone || moment.tz.guess()

  const parsed = moment.tz(date, 'Greenwich').tz(userTimeZone)

  return moment(parsed).format(format)
}

export const getCheckIn = (
  date: string | Moment,
  dateFormat = 'ddd, MMM DD, YYYY'
): string => moment(date).format(dateFormat)

export const getCheckOut = (
  date: string | Moment,
  duration: number,
  dateFormat = 'ddd, MMM DD, YYYY'
): string =>
  moment(date)
    .add(duration - 1, 'days')
    .format(dateFormat)

export const getCheckInOut = ({
  prefixCheckIn = '',
  prefixCheckOut = '',
  date,
  dateFormat = 'ddd, MMM DD, YYYY',
  duration = 1,
  extraNights = { before: 0, after: 0 },
}: {
  prefixCheckIn?: string
  prefixCheckOut?: string
  date: string
  dateFormat?: string
  duration?: number
  extraNights?: { before?: number; after?: number }
}): string => {
  const checkin = getCheckIn(
    moment(date).subtract(extraNights.before, 'days'),
    dateFormat
  )
  const checkout = getCheckOut(
    moment(date).add(extraNights.after, 'days'),
    duration,
    dateFormat
  )

  if (duration === 1) {
    return `${prefixCheckIn}${checkin}`
  }
  return `${prefixCheckIn}${checkin} - ${prefixCheckOut}${checkout}`
}

export const formatTime = (
  time: string,
  from = 'HH:mm:ss',
  to = 'hh:mm A'
): string => {
  if (!time) return ''

  return moment(time, from).format(to)
}

export const formatDate = (date: string, format = 'YYYY-MM-DD'): string => {
  if (!date) return ''
  return moment(date).format(format)
}

/**
 * Get the RSVP deadline
 * @param {
 *    date: string
 *    format?: string
 *    plainDate?: boolean // to return only the date in plain text
 *    customText?: string // default: 'Last call for RSVP edits by'
 * }
 * @returns string | React.ReactNode
 */
export const rsvpDeadline = ({
  date,
  format = 'DD MMMM YYYY',
  plainDate = false,
  customText = 'Last call for RSVP edits by',
  deadline = 0,
  softDeadline,
}: {
  date: string
  format?: string
  plainDate?: boolean
  customText?: string
  deadline: number
  softDeadline?: string
}): string | React.ReactNode => {
  if (!date || !deadline) return '' // hide text

  if (softDeadline) {
    const softDeadlinePassed = moment()
      .startOf('day')
      .isAfter(moment(softDeadline).startOf('day'))
    if (!softDeadlinePassed) return ''
  }

  const response = moment(date)
    .subtract(deadline, 'days')
    .startOf('day')
    .format(format)

  if (plainDate) return response

  return (
    <span className="fz-11 tc-lgray d-block w-100 mb-2 px-2">
      {customText} <b>{response}</b>
    </span>
  )
}

/**
 * Verify if the RSVP can be declined based on the minimum date
 * @param dateToVerify
 * @returns boolean
 */
export const verifyMinimumDateRSVP = (
  dateToVerify: string,
  deadline = 0
): boolean => {
  if (!dateToVerify) return false

  // start of the day to avoid issues with the time
  const dateToVerifyMoment = moment(dateToVerify).startOf('day')
  const minimumDate = moment().startOf('day').add(deadline, 'days')

  return dateToVerifyMoment.isSameOrAfter(minimumDate)
}
