import React from 'react'
import moment from 'moment'
import { IoMdCalendar } from 'react-icons/io'

import { getCheckIn, getCheckInOut, getCheckOut } from '../../common'
import { salmon } from '../../styles/themeColors'

export const DisplayRoomBlockDates: React.FC<{
  mm: number
  checkInOut?: boolean
  checkin: string
  checkout: string
  duration?: number
  conatinerClass?: string
  small?: boolean
}> = ({
  mm,
  checkin,
  checkout,
  duration = 0,
  conatinerClass,
  small = false,
  checkInOut = false,
}) => {
  return (
    <div className={conatinerClass ?? 'w-100'}>
      <table className={small ? 'mb-3' : 'mt-3 mb-4 fz-16'}>
        <tbody>
          {checkInOut && (
            <tr>
              <td className={small ? 'pr-3 fwb' : 'pr-3 fwb pb-2'}>
                Room block dates:
              </td>
              <td
                className={
                  small ? 'text-primary fwsb' : 'text-primary fwsb pb-2'
                }
              >
                {getCheckInOut({
                  date: checkin,
                  dateFormat: 'MMM DD, YYYY',
                  duration,
                })}
              </td>
            </tr>
          )}
          <tr>
            <td className="pr-3 fwb">Earliest available:</td>
            <td>
              <IoMdCalendar
                size={small ? 20 : 28}
                color={salmon}
                className={small ? 'mb-1 mr-1' : 'mr-2 mb-1'}
              />
              {getCheckIn(moment(checkin, 'YYYY-MM-DD').subtract(mm, 'days'))}
            </td>
          </tr>
          <tr>
            <td className="pr-3 fwb">Latest available:</td>
            <td>
              <IoMdCalendar
                size={small ? 20 : 28}
                color={salmon}
                className={small ? 'mb-1 mr-1' : 'mr-2 mb-1'}
              />
              {getCheckOut(
                moment(checkout, 'YYYY-MM-DD').add(mm, 'days'),
                duration
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
