export const salmon = '#ff6e78'
export const cardBorder = 'rgba(219, 219, 219, 0.80)'
export const darkGray = '#4a4a4a'
export const gray = '#858484'
export const borderGray = '#a4a4a5'
export const lightGray = '#dbdbdb'
export const lightSalmon = '#ffd3d6'
export const lightBackground = '#f2f3f5'
export const grayBackground = '#f7f7f8'
export const green = '#1CD589'
export const red = '#C60000'
export const lightGreen = '#54B448'
export const yellow = '#ffc300'
export const pink = '#fad3d7'
export const smsBackground = '#e9e9eb'

// Color Palette from Instagram - https://www.instagram.com/doyoukissandtell/
export const colorPalette = {
  color1: '#ff6e79',
  color2: '#fb9272',
  color3: '#7fc3c2',
  color4: '#fc8ea7',
  color5: '#fce2d3',
  color6: '#85a9b5',
}

// face icons color palette
export const faceIconColor = {
  neutral: '#DAA520',
  happy: '#66CDAA',
  sad: '#FF6B6B',
}
