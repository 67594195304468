import React, { useState, useEffect } from 'react'
import toLower from 'lodash/fp/toLower'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Container from 'react-bootstrap/Container'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import {
  MdLockOutline,
  MdMailOutline,
  MdWarning,
  MdCheckCircle,
} from 'react-icons/md'

import { KnTField } from '../../components'
import { simpleAlert, useValidatePassword } from '../../common'
import { useForm } from '../../common/alterForms'
import { RESET_PASSWORD, RESET_PASSWORD_LINK } from '../../graphql'
import { Error } from '../../types'

export const ResetPassword: React.FC = () => {
  const { token } = useParams<{ token: string }>()
  const [sending, setSending] = useState(false)
  const [resetComplete, setResetComplete] = useState(false)
  const [submitReset] = useMutation(RESET_PASSWORD)
  const [submitResetLink] = useMutation(RESET_PASSWORD_LINK)

  const initModel = { email: '', password: '', password2: '' }
  const { model, errors, handleFieldChange, reset, pushError } =
    useForm(initModel)
  const validatePass = useValidatePassword(model.password)

  const sendForm = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    if (token && validatePass.length > 0) return
    if (token && model.password !== model.password2) {
      pushError('Passwords must match', 'password')
      return
    }

    setSending(true)
    if (!token) {
      const variables = { email: toLower(model.email) }
      submitResetLink({ variables })
        .then(({ data }) => {
          if (data.passwordResetLink.errors.length > 0) {
            data.passwordResetLink.errors.forEach(({ key, message }: Error) => {
              pushError(message, key)
            })
          } else {
            simpleAlert({
              html: `A password reset email was sent to ${model.email} if a matching account was found`,
              icon: 'success',
            })
            // Successfully reset
            reset(initModel)
          }
        })
        .catch(() => {
          simpleAlert({
            html: 'Something went wrong, please try again later',
            icon: 'warning',
          })
        })
        .then(() => setSending(false))
    } else if (model.password === model.password2 && token) {
      const variables = {
        input: { password: model.password, secret: token },
      }
      submitReset({ variables })
        .then(({ data }) => {
          if (data.resetPassword.errors.length > 0) {
            data.resetPassword.errors.forEach(({ key, message }: Error) => {
              if (key === 'secret') {
                simpleAlert({ html: message, icon: 'warning' })
              } else {
                pushError(message, key)
              }
            })
          } else {
            setResetComplete(true)
          }
        })
        .catch(() => {
          simpleAlert({
            html: 'Something went wrong, please try again later',
            icon: 'warning',
          })
        })
        .then(() => setSending(false))
    }
  }

  // since the password2 must match password, we need to validate it
  useEffect(() => {
    if (validatePass.length <= 0) {
      pushError('', 'password')
      return
    }
    if (model.password && validatePass.length > 0) {
      pushError(validatePass[0].message, 'password')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.password, validatePass, model.password2])

  if (resetComplete) {
    return (
      <Card
        body
        style={{ maxWidth: '500px' }}
        className="mx-auto py-4 my-5 shadow-sm"
      >
        <div className="text-center">
          <MdCheckCircle color="#1CD589" size={50} />
          <p className="fwb fz-24 mt-2">Password reset successful!</p>
        </div>
      </Card>
    )
  }

  return (
    <Container className="pt-5 pb-3">
      <Helmet>
        <title>Kiss & Tell | Reset Password</title>
        <meta
          name="description"
          content="Reset your password on Kiss & Tell. Get back to planning your event."
        />
      </Helmet>
      <Card style={{ maxWidth: '500px' }} className="mx-auto">
        <Card.Body>
          <Card.Text className="text-center">
            <MdLockOutline size={80} color="#FF6E78" />
          </Card.Text>
          <Card.Title className="fwb fz-24 text-center">
            Reset Password
          </Card.Title>
          <Form
            onSubmit={sendForm}
            className="mx-auto"
            style={{ maxWidth: '360px' }}
          >
            {!token && (
              <KnTField
                error={errors}
                value={model}
                setValue={handleFieldChange}
                placeholder="your-email@example.com"
                name="email"
                type="email"
                icon={<MdMailOutline size={24} />}
              />
            )}

            {token && (
              <>
                <KnTField
                  label="New password"
                  value={model}
                  error={errors}
                  setValue={handleFieldChange}
                  name="password"
                  placeholder="your new password"
                  type="password"
                  icon={<MdLockOutline size={24} />}
                />
                <KnTField
                  label="Confirm new password"
                  value={model}
                  error={errors}
                  setValue={handleFieldChange}
                  name="password2"
                  placeholder="your new password"
                  type="password"
                  icon={
                    model.password === model.password2 ? (
                      <></>
                    ) : (
                      <MdWarning title="Password doesn't match" size={24} />
                    )
                  }
                />
              </>
            )}
            <div>
              <Button
                className="py-3 mt-4 mb-3 w-100 fwsb"
                type="submit"
                variant="primary"
                disabled={sending}
                style={{ borderRadius: '50px' }}
              >
                {token ? 'Submit' : 'Send reset link'}
              </Button>
              {sending && (
                <Spinner className="float-right mt-1" animation="border" />
              )}
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  )
}
