import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import { MdMoreHoriz } from 'react-icons/md'
import { useMutation } from '@apollo/react-hooks'

import { useAppSelector } from '../../../hooks'
import { DELETE_COMMENT } from '../../../graphql'
import { Error, Comment } from '../../../types'

import { deleteCommentCache } from './commentsCacheFunctions'
import { CommentOption } from './style'

interface ICommentOptions {
  comment: Comment
  setEditComment: React.Dispatch<React.SetStateAction<boolean>>
  postId: string
  preview: boolean
}

export const CommentOptions: React.FC<ICommentOptions> = ({
  comment,
  setEditComment,
  postId,
  preview,
}) => {
  const { currentEventId } = useAppSelector(state => state.site)
  const [dialogError, setDialogError] = useState('')
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [deleteSingleComment, { loading: loadingDelete }] = useMutation(
    DELETE_COMMENT,
    {
      update(cache, { data: { deleteComment } }) {
        if (deleteComment.errors.length === 0) {
          deleteCommentCache(
            cache,
            postId,
            preview,
            deleteComment.result.id,
            currentEventId
          )
        }
      },
    }
  )

  const HandleDeleteComment = (
    ev: React.MouseEvent<HTMLButtonElement>
  ): void => {
    ev.preventDefault()
    const variables = { id: comment.id }
    deleteSingleComment({ variables }).then(({ data }) => {
      if (data.deleteComment.errors.length > 0) {
        data.deleteComment.errors.forEach(({ message }: Error) =>
          setDialogError(message)
        )
      } else {
        setShowDeleteDialog(false)
      }
    })
  }

  return (
    <CommentOption className="options-comments">
      <Dropdown alignRight>
        <Dropdown.Toggle split id="post-options" className="custom-options">
          <MdMoreHoriz size={16} color="#4a4a4a" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setEditComment(true)}>
            Edit
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setShowDeleteDialog(true)}>
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Modal show={showDeleteDialog} onHide={() => setShowDeleteDialog(false)}>
        <Modal.Header className="py-3">
          <h5 className="font-weight-bold m-0">Delete Comment?</h5>
        </Modal.Header>
        <Modal.Body>
          <p className="m-0">Are you sure you want to delete this comment?</p>
          {dialogError && (
            <span className="tc-error fz-12 mt-3 d-block">{dialogError}</span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loadingDelete}
            onClick={HandleDeleteComment}
            variant="primary"
          >
            Delete it
          </Button>
          <Button
            onClick={() => setShowDeleteDialog(false)}
            variant="secondary"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </CommentOption>
  )
}
