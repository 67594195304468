import React, { useState, HTMLAttributes, FormEvent } from 'react'
import map from 'lodash/fp/map'
import set from 'lodash/fp/set'
import get from 'lodash/fp/get'
import filter from 'lodash/fp/filter'
import { useMutation } from '@apollo/react-hooks'
import { FaTimesCircle, FaPen, FaCheck, FaTimes } from 'react-icons/fa'

import { DELETE_TIER, UPDATE_TIER, GET_MY_EVENT } from '../../graphql'
import { Tier, GetMyEventQuery, MyEvent, Guest, Maybe } from '../../types'
import { confirmAlert } from '../../common'

interface ITiersItemEditing {
  tier: Tier
  event: MyEvent
  updateSelection: (id: string) => void
}

const ItemEditing: React.FC<
  ITiersItemEditing & HTMLAttributes<HTMLDivElement>
> = ({ tier, event, className, updateSelection }) => {
  const [editing, toggleEditing] = useState(false)
  const [tempTier, updateTempTier] = useState('')
  const [error, setError] = useState(false)

  const [handleDeleteTier] = useMutation(DELETE_TIER, {
    update(cache, { data: { deleteTier } }) {
      if (deleteTier.errors.length === 0) {
        const cached: Maybe<GetMyEventQuery> = cache.readQuery({
          query: GET_MY_EVENT,
          variables: { id: event.id, tierIds: [] },
        })

        if (cached) {
          const tiers = filter(
            (t: Tier) => t.id !== tier.id,
            get('me.myEvents[0].tiers', cached) || []
          )

          const guests = map(
            (g: Guest) =>
              set(
                'tiers',
                filter((t: Tier) => t.id !== tier.id, g.tiers),
                g
              ),
            get('me.myEvents[0].guests', cached) || []
          )

          cache.writeQuery({
            query: GET_MY_EVENT,
            data: set(
              'me.myEvents[0].guests',
              guests,
              set('me.myEvents[0].tiers', tiers, cached)
            ),
            variables: { id: event.id },
          })

          updateSelection(tier.id)
        }
      }
    },
  })

  const [performTierUpdate] = useMutation(UPDATE_TIER)

  const destroyTier = (): void => {
    confirmAlert({
      html: 'Are you sure you want to delete this sub-list?',
    }).then(response => {
      if (response.value) {
        const variables = {
          id: tier.id,
        }
        handleDeleteTier({ variables })
      }
    })
  }

  const handleUpdateTier = () => {
    const variables = {
      id: tier.id,
      input: {
        name: tempTier,
      },
    }
    performTierUpdate({ variables }).then(({ data: { updateTier } }) => {
      if (updateTier.errors.length === 0) {
        toggleEditing(false)
      } else {
        setError(true)
      }
    })
  }

  const startEditing = () => {
    updateTempTier(tier.name)
    toggleEditing(true)
    setTimeout(() => {
      const field = document.getElementById(`tierEdit${tier.id}`)
      if (field) {
        field.focus()
      }
    }, 50)
  }

  const cancel = () => {
    setError(false)
    toggleEditing(false)
  }

  return (
    <div
      className={`tier-item-editing ${className} ${
        editing ? 'editing' : 'btn-gray'
      } ${error && 'error'}`}
    >
      {editing ? (
        <>
          <form
            onSubmit={(e: FormEvent) => {
              e.preventDefault()
              handleUpdateTier()
            }}
          >
            <input
              type="text"
              id={`tierEdit${tier.id}`}
              value={tempTier}
              onChange={({ currentTarget: { value } }) => {
                setError(false)
                updateTempTier(value)
              }}
              className="border-0"
            />
          </form>
          <button className="ml-1" onClick={handleUpdateTier} type="button">
            <FaCheck />
          </button>
          <button className="ml-1" onClick={cancel} type="button">
            <FaTimes />
          </button>
        </>
      ) : (
        <>
          <span className="px-1" onClick={startEditing}>
            {tier.name}
          </span>
          <button className="ml-1" onClick={startEditing} type="button">
            <FaPen />
          </button>
          <button className="ml-1" onClick={destroyTier} type="button">
            <FaTimesCircle />
          </button>
        </>
      )}
    </div>
  )
}
export default ItemEditing
