import React from 'react'
import find from 'lodash/fp/find'
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'

import { CREATE_BOOKING } from '../../../graphql'
import { EventIcon, RoomIcon } from '../../../components/customIcons'
import { offeringTypes } from '../../../common/constants'
import { errorAlert, loaderAlert } from '../../../common'
import { EventComponent, MyEvent } from '../../../types'

interface IRSVPcta {
  eventComponent: EventComponent
  className?: string
  myEvents?: MyEvent[]
  RsvpCustomText?: string
  RoomCustomText?: string
  customCallback?: () => void
}

export const RSVPcta: React.FC<IRSVPcta> = ({
  eventComponent,
  className,
  children,
  myEvents,
  RsvpCustomText = 'RSVP',
  RoomCustomText = 'Book',
  customCallback,
}) => {
  const history = useHistory()
  const [createBooking, { loading }] = useMutation(CREATE_BOOKING)
  const { roomOffering } = offeringTypes

  const handleCreateBooking = (ev: React.MouseEvent) => {
    ev.preventDefault()
    loaderAlert({ html: 'processing your booking' })

    const variables = {
      eventComponentId: eventComponent.id,
    }
    createBooking({ variables })
      .then(({ data }) => {
        Swal.close()
        const errors = data?.createBooking?.errors || []
        const result = data?.createBooking?.result

        if (errors.length > 0) {
          errorAlert(errors, 'There was an error booking this property')
        } else {
          customCallback?.()
          history.push(`/booking/${result?.id}`)
        }
      })
      .catch(error => errorAlert([], error.message))
  }

  const isMyEvent = !!find({ id: eventComponent.eventId }, myEvents)
  if (children) {
    return (
      <Button
        className={className}
        onClick={handleCreateBooking}
        disabled={loading}
      >
        {children}
      </Button>
    )
  }
  if (isMyEvent) {
    return (
      <Button
        className={className}
        onClick={handleCreateBooking}
        disabled={loading}
      >
        Details
      </Button>
    )
  }
  return (
    <Button
      className={className}
      onClick={handleCreateBooking}
      disabled={loading}
    >
      {eventComponent.offering.__typename === roomOffering ? (
        <>
          <RoomIcon color="white" width="25px" height="25px" />
          {RoomCustomText}
        </>
      ) : (
        <>
          <EventIcon color="white" width="25px" height="25px" />
          {RsvpCustomText}
        </>
      )}
    </Button>
  )
}
