import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import Container from 'react-bootstrap/Container'
import flow from 'lodash/fp/flow'
import head from 'lodash/fp/head'
import toNumber from 'lodash/fp/toNumber'
import orderBy from 'lodash/fp/orderBy'
import { Link, useLocation } from 'react-router-dom'
import { MdOutlineAirplanemodeActive, MdCalendarToday } from 'react-icons/md'
import { IoMdCalendar } from 'react-icons/io'

import { FormatNumber, PackageCustomizations } from '../../../components'
import { salmon } from '../../../styles/themeColors'
import {
  getCheckInOut,
  getCheckIn,
  getCheckOut,
  orderPackageAddons,
  useQueryParams,
} from '../../../common'
import { offeringTypes, guestStatuses } from '../../../common/constants'
import { EventComponent, IEventSegments, IEventSegment } from '../../../types'

import {
  EventComponentOffering,
  RSVPslider,
  ValidationCTA,
  AddToCalendar,
  groupComponentsByDate,
  sortPackagesFirst,
} from '.'

export const Segment: React.FC<IEventSegment> = ({
  components,
  guest,
  eventName,
  isMyEvent = false,
  className,
  coverSize,
  eventId,
}) => {
  const { pathname } = useLocation()
  const { preview } = useQueryParams() // to use in the iframe /event/{id}/settings
  const isPreview = pathname.includes('rsvp-preview')

  const [idx, setIdx] = useState(0)
  const { packageOffering } = offeringTypes
  if (
    components.length === 1 &&
    components[0].offering.__typename === packageOffering
  ) {
    const { accepted } = guestStatuses
    const {
      alias,
      offering,
      date,
      duration,
      packageCustomization = [],
    } = components[0]
    const pc = orderPackageAddons(packageCustomization)

    return (
      <Card className={className || `mb-4`}>
        <Row>
          <Col xs={12} lg={5}>
            <EventComponentOffering
              className="mt-0 rounded-25-0-0-25"
              eventComponent={components[0]}
              isMyEvent={isMyEvent}
              big
              guest={guest}
              externalValidation={
                guest?.status !== accepted ? (
                  <ValidationCTA type={offering.__typename} />
                ) : undefined
              }
              hideText
            />
          </Col>
          <Col xs={12} lg={7}>
            <div className="border-b-1 d-flex justify-content-between flex-wrap flex-lg-nowrap px-3 px-lg-0 py-3">
              <h2 className="fwb fz-24">{alias || offering.name}</h2>
              <AddToCalendar
                eventName={eventName}
                offering={offering}
                date={date}
                duration={duration}
                alias={alias}
                className="text-primary px-0 px-lg-4"
              />
            </div>
            <div className="border-b-1 d-flex justify-content-between flex-wrap flex-lg-nowrap align-items-start px-3 px-lg-0 py-2">
              <div className="mr-auto">
                <Link
                  onClick={ev => preview && ev.preventDefault()}
                  className="fwsb fz-18"
                  to={`/events-list/${eventId}/${offering.provider.id}${
                    isPreview ? '?previewpage=true' : ''
                  }`}
                >
                  {offering.provider.name}
                </Link>

                <p className="fwsb">{offering.provider.location}</p>
              </div>
              <div className="w-100 d-block d-lg-none" />
              <div className="d-flex align-items-center fwsb px-1 px-lg-3">
                <MdOutlineAirplanemodeActive
                  size={35}
                  color={salmon}
                  className="m-2"
                />
                <span className="d-inline-block text-left pl-2">
                  {offering.provider.timeToAirport} min
                  <br />
                  {offering.provider.airportCode}
                </span>
              </div>
              <div className="fwsb px-3">
                <div className="d-flex align-items-center">
                  <MdCalendarToday size={36} color={salmon} className="m-2" />
                  <span className="d-inline-block">
                    {getCheckIn(date, 'MMM Do, YYYY')} <br />
                    {getCheckIn(date, 'dddd')}
                  </span>
                </div>
              </div>
            </div>
            {pc && <PackageCustomizations customizations={pc} />}
          </Col>
        </Row>
      </Card>
    )
  }

  const current = components[idx - 1]
  const { provider } = components[0].offering
  const longestStay = flow(
    orderBy(['duration'], ['desc']),
    head
  )(components) as EventComponent
  return (
    <Card className={className || `mb-4`}>
      <Row>
        <Col xs={12} lg={5}>
          <RSVPslider
            onChange={setIdx}
            list={components}
            guest={guest}
            coverSize={coverSize}
          />
        </Col>
        <Col xs={12} lg={7}>
          <div className="border-b-1 d-flex justify-content-between px-3 px-lg-0 py-3">
            <h2 className="fwb fz-24">Guest Rooms</h2>
            {idx > 0 && (
              <AddToCalendar
                eventName={eventName}
                offering={current.offering}
                date={current.date}
                duration={current.duration}
                alias={current.alias}
                className="text-primary px-1 px-lg-4"
              />
            )}
          </div>
          <div className="border-b-1 d-flex justify-content-between align-items-start flex-wrap flex-lg-nowrap px-3 px-lg-0 py-3">
            <div style={{ maxWidth: '300px' }} className="mr-auto">
              <Link
                onClick={ev => preview && ev.preventDefault()}
                className="fwsb fz-18"
                to={`/room-selection/${eventId}/${provider.id}${
                  isPreview ? '?previewpage=true' : ''
                }`}
              >
                {provider.name}
              </Link>
              {idx > 0 && (
                <p className="m-0 fwb fz-18">{current.offering.name}</p>
              )}
              <p className="fwsb">{provider.location}</p>
              {idx > 0 && (
                <>
                  <FormatNumber
                    n={
                      toNumber(current.offering.price) *
                      toNumber(current.guestPays)
                    }
                  />
                  / nightly rate
                  <div className="mt-2">
                    {current.tiers.map(tier => (
                      <Badge
                        pill
                        key={tier.id}
                        className="mr-1 py-1 badge-gray fz-13"
                      >
                        {tier.name}
                      </Badge>
                    ))}
                  </div>
                </>
              )}
            </div>
            <div className="w-100 d-block d-lg-none" />
            <div className="d-flex align-items-center fwsb px-1 px-lg-3 pt-1">
              <MdOutlineAirplanemodeActive size={35} color={salmon} />
              <span className="d-inline-block text-left pl-2">
                {provider.timeToAirport} min
                <br />
                {provider.airportCode}
              </span>
            </div>
            <div className="fwsb px-1 px-lg-3">
              <span className="d-block">
                <IoMdCalendar size={30} color={salmon} /> Check In
              </span>
              {getCheckIn(idx > 0 ? current.date : longestStay.date)}
            </div>
            <div className="fwsb px-1 px-lg-3">
              <span className="d-block">
                <IoMdCalendar size={30} color={salmon} /> Check Out
              </span>
              {getCheckOut(
                idx > 0 ? current.date : longestStay.date,
                idx > 0 ? current.duration : longestStay.duration
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  )
}

type ECorList = (EventComponent | { date: string; rooms: EventComponent[] })[]

export const EventSegments: React.FC<IEventSegments> = ({
  guest,
  eventName,
  eventComponents,
  eventId,
}) => {
  if (eventComponents.length <= 0) {
    return (
      <Container className="text-center mt-5 py-5">
        <h2>
          No rooms or events available at the moment. Please check back later!
        </h2>
      </Container>
    )
  }
  const groupedByDate = groupComponentsByDate(eventComponents)
  return (
    <>
      <h2 className="text-center mb-0 mt-5">Event Segments</h2>
      {groupedByDate.map((group: ECorList) => {
        const date = getCheckInOut({
          date: group[0].date,
          dateFormat: 'dddd, MMMM Do, YYYY',
        })

        // to render the packages first
        const groupSorted = sortPackagesFirst(group)

        return (
          <Container key={`group-${date}`} className="my-5">
            <h3 className="pb-2">{date}</h3>
            {groupSorted.map(g => {
              if ('rooms' in g) {
                // all rooms
                return (
                  <Segment
                    key={`rooms-row-${g.rooms[0].id}`}
                    guest={guest}
                    components={g.rooms}
                    eventName={eventName}
                    eventId={eventId}
                  />
                )
              }

              // 1 row for each package
              return (
                <Segment
                  key={`row-${g.id}`}
                  guest={guest}
                  components={[g]}
                  eventName={eventName}
                  eventId={eventId}
                />
              )
            })}
          </Container>
        )
      })}
    </>
  )
}
