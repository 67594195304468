import React from 'react'
import Card from 'react-bootstrap/Card'
import { TbMoodKid } from 'react-icons/tb'

import { RSVPtitle } from '../style'
import { defaultIntro } from '../rsvpFunctions'
import user from '../../../../assets/images/rsvp/user.jpg'
import { IInvitationBody } from '../../../../types'
import {
  getCheckInOut,
  getImageUrl,
  replaceLineBreaksWithBR,
} from '../../../../common'

import { Template1Container } from './style'

export const Template1: React.FC<
  Pick<
    IInvitationBody,
    'mainProvider' | 'event' | 'plusOne' | 'kidsAllowed'
  > & { guest?: IInvitationBody['guest'] }
> = ({ event, mainProvider, plusOne, guest, kidsAllowed, children }) => {
  const bgImage = getImageUrl(mainProvider.mainImage, '1300x600')

  return (
    <Template1Container>
      <div className="rsvp-bg d-none d-sm-inline-block">
        <img src={bgImage} alt={mainProvider.name} />
      </div>
      <RSVPtitle
        className="font-lf"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        {event.name}
      </RSVPtitle>
      <Card
        body
        style={{ maxWidth: '700px' }}
        className="mx-auto shadow-sm pb-2 text-center"
      >
        <div>
          <p className="fz-24 m-0 text-primary fwb">You’re Invited!</p>
          <img
            src={event.mainImage || user}
            alt="User"
            className="mw-landing-img"
          />
          <span className="d-block tc-gray fz-20 fwb mt-1 no-under">
            {mainProvider.name}
          </span>
          <p
            style={{ maxWidth: '320px' }}
            className="fz-11 tc-lgray fwn mx-auto mt-0 mb-1 fwn"
          >
            {mainProvider?.address}
          </p>
          <span className="text-primary fz-14 d-block fwsb">
            {mainProvider.location}
          </span>
          <span className="fz-16  fwsb">
            {getCheckInOut({
              date: event.date,
              dateFormat: 'MMM DD, YYYY',
              duration: event.duration,
            })}
          </span>

          {plusOne && (
            <span className="d-block pt-2">
              Number of +1s for you: <b>{plusOne}</b>
            </span>
          )}

          {kidsAllowed && (
            <span className="d-block pt-2">
              Children permitted <TbMoodKid size={20} />
            </span>
          )}
        </div>
        <p className="fz-24 pt-4 fwb">Will you join us?</p>
        <p className="pt-1 px-0 px-sm-5">
          {guest && (
            <>
              Hey {guest?.firstName}! <br />
            </>
          )}
          {event.description
            ? replaceLineBreaksWithBR(event.description)
            : defaultIntro()}
        </p>
        {children}
      </Card>
    </Template1Container>
  )
}
