import React, { useState } from 'react'
import get from 'lodash/fp/get'
import set from 'lodash/fp/set'
import remove from 'lodash/fp/remove'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import { MdMoreHoriz } from 'react-icons/md'
import { useMutation } from '@apollo/react-hooks'

import { useAppSelector } from '../../../hooks'
import { DELETE_POST, GET_EVENT_FEEDS } from '../../../graphql'
import { Maybe, FeedQuery, Post, Error } from '../../../types'

import { PostOption } from './style'

import { EditPost, PinPost } from '.'

export const PostOptions: React.FC<{ post: Post }> = ({ post }) => {
  const { currentEventId } = useAppSelector(state => state.site)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [dialogError, setDialogError] = useState('')

  const [deletePostMutation, { loading: loadingDelete }] = useMutation(
    DELETE_POST,
    {
      update(cache, { data: { deletePost } }) {
        if (deletePost.errors.length === 0) {
          const cached: Maybe<FeedQuery> = cache.readQuery({
            query: GET_EVENT_FEEDS,
            variables: { eventIds: [currentEventId] },
          })
          if (cached) {
            const posts = get('feed.entries', cached) || []
            cache.writeQuery({
              query: GET_EVENT_FEEDS,
              variables: { eventIds: [currentEventId] },
              data: set(
                'feed.entries',
                remove((el: Post) => el.id === deletePost.result.id, posts),
                cached
              ),
            })
          }
        }
      },
    }
  )

  const handleDeletePost = (ev: React.MouseEvent<HTMLButtonElement>): void => {
    ev.preventDefault()
    const variables = { id: post.id }
    deletePostMutation({ variables }).then(({ data }) => {
      if (data.deletePost.errors.length > 0) {
        data.deletePost.errors.forEach(({ message }: Error) =>
          setDialogError(message)
        )
      } else {
        setShowDeleteDialog(false)
      }
    })
  }

  return (
    <PostOption>
      <PinPost post={post} />
      <Dropdown alignRight>
        <Dropdown.Toggle split id="post-options" className="custom-options">
          <MdMoreHoriz size={20} color="#4a4a4a" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setShowEditDialog(true)}>
            Edit
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setShowDeleteDialog(true)}>
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Modal show={showDeleteDialog} onHide={() => setShowDeleteDialog(false)}>
        <Modal.Header className="py-3">
          <h5 className="font-weight-bold m-0">Delete Post?</h5>
        </Modal.Header>
        <Modal.Body>
          <p className="m-0">
            You can edit it if you just need to change something.
          </p>
          <p className="m-0">Are you sure you want to delete it?</p>
          {dialogError && (
            <span className="tc-error fz-12 mt-3 d-block">{dialogError}</span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loadingDelete}
            onClick={handleDeletePost}
            variant="primary"
          >
            Delete it
          </Button>
          <Button
            onClick={() => setShowDeleteDialog(false)}
            variant="secondary"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <EditPost show={showEditDialog} setShow={setShowEditDialog} post={post} />
    </PostOption>
  )
}
