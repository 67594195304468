import styled from 'styled-components'

export const Profile = styled.span`
  background-color: #ffd3d6;
  border-radius: 50%;
  display: inline-block;
  justify-content: center;
  padding: 8px;
  vertical-align: top;
`
