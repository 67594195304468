import React, { useState, useEffect } from 'react'
import get from 'lodash/fp/get'
import Card from 'react-bootstrap/Card'
import { MdChatBubble } from 'react-icons/md'
import { RiArrowGoBackFill } from 'react-icons/ri'

import { Post, Event, MyEvent } from '../../../types'
import { fromNow, useCurrentUser } from '../../../common'
import { HeadPost, MainActions, Postcontent } from '../style'
import { RenderTiers } from '../../../components/Tiers/RenderTiers'
import { DefaultUserPicture, UserPicture } from '../../../components'
import { red } from '../../../styles/themeColors'

import { PinnedPostsContainer } from './style'

import { Comments, PollResult, PostOptions, VideoPost } from '.'

export const PinnedPosts: React.FC<{
  posts: Post[]
  events: (MyEvent | Event)[]
}> = ({ posts = [], events = [] }) => {
  const [showReply, setShowReply] = useState(() =>
    posts.map(p => ({ [p.id]: false }))
  )
  const [expanded, setExpanded] = useState<string>()
  const { userId } = useCurrentUser()

  useEffect(() => {
    if (posts.length === 1) {
      setExpanded(posts[0].id)
    }
    if (posts.length > 1) {
      setExpanded(undefined)
    }
  }, [posts])

  if (posts.length <= 0) return null
  const postsToShow = expanded ? posts.filter(p => p.id === expanded) : posts

  return (
    <PinnedPostsContainer className="pinned-posts">
      {postsToShow.map(post => {
        const avatarUrl = post.user?.avatarUrl
        const userName = `${post.user.firstName} ${post.user.lastName}`
        const myEvent = userId === post.user?.id
        const event = events.find(ev => ev.id === post.eventId)

        // limit characters but if the post is expanded, show the whole body
        const limit = post.poll || post.video ? 45 : 300
        const truncateBody = expanded ? false : post.body.length > limit
        const postBody = truncateBody
          ? `${post.body.slice(0, limit)}...`
          : post.body

        const showReplyPost: boolean = get(post.id, showReply)
        const handleShowReply = (ev: React.MouseEvent) => {
          ev.preventDefault()
          setShowReply({ ...showReply, [post.id]: true })
        }
        return (
          <Card
            data-cy={post.poll ? 'post-card-poll' : 'post-card'}
            className="pinned-post shadow-sm"
          >
            {!expanded && (
              <div
                title="Expand post"
                className="pinned-post-overlay cursor"
                onClick={() => setExpanded(post.id)}
              />
            )}
            <Card.Body className="p-2 p-md-3">
              <HeadPost>
                {myEvent && <PostOptions key={post.id} post={post} />}

                {expanded && posts.length > 1 && (
                  <RiArrowGoBackFill
                    size={20}
                    color={red}
                    title="Close"
                    className="cursor mr-2"
                    onClick={() => setExpanded(undefined)}
                  />
                )}
                {avatarUrl ? (
                  <UserPicture name={userName} url={avatarUrl} />
                ) : (
                  <DefaultUserPicture />
                )}
                <div className="info">
                  <h5 className="mb-0 fz-16">
                    {userName} in {event?.name}
                  </h5>
                  <small className="text-muted">
                    {fromNow(post.insertedAt)}
                  </small>
                </div>
                <RenderTiers
                  tags={post.tiers}
                  eventId={post.eventId}
                  events={events}
                />
              </HeadPost>
              <div className="pb-2">
                <Postcontent
                  className="mb-1 pb-3 pt-1 px-1"
                  dangerouslySetInnerHTML={{ __html: postBody }}
                />
                {post.video && <VideoPost video={post.video} />}
                {post.poll && <PollResult owned={myEvent} post={post} />}
              </div>

              {expanded && (
                <>
                  <MainActions className="fz-13 fwsb mt-auto">
                    <span onClick={handleShowReply} className="cursor p-2">
                      <MdChatBubble size={16} /> Comment
                    </span>
                  </MainActions>
                  <Comments
                    reply={showReplyPost}
                    count={post.commentCount}
                    commentsPreview={post.commentsPreview}
                    postId={post.id}
                  />
                </>
              )}
              {!expanded && (
                <span className="pinned-post-count px-2 px-md-3">
                  <MdChatBubble size={16} /> {post.commentCount}
                </span>
              )}
            </Card.Body>
          </Card>
        )
      })}
    </PinnedPostsContainer>
  )
}
