import React from 'react'
import get from 'lodash/fp/get'
import sum from 'lodash/fp/sum'
import sumBy from 'lodash/fp/sumBy'
import toNumber from 'lodash/fp/toNumber'
import round from 'lodash/round'

import { addonMetadata, offeringTypes } from '../../../common/constants'
import { FormatNumber, SimpleTooltip } from '../../../components'
import {
  IcurrentAddons,
  PriceComponents,
  Offering,
  BookingAddOn,
} from '../../../types'

const extraNights = (extra: BookingAddOn, offering: Offering): JSX.Element => {
  const { offeringAddOn, offeringAddOnOption, id } = extra
  const numberOfNights = toNumber(extra.offeringAddOnOption?.label.charAt(0))
  const addonPrice = extra.offeringAddOnOption?.price

  return (
    <tr className="fz-13">
      <td>
        <span className="fz-12 pl-2">
          {`${offeringAddOnOption?.quantity} ${offeringAddOn.label}`}
          {!offeringAddOnOption?.price && (
            <SimpleTooltip
              id={`${id}-extra-night-desc`}
              className="tooltip-card"
              text={`$${round(offering.price, 2)} /nightly rate`}
            />
          )}
        </span>
      </td>
      <td className="pl-3">
        <FormatNumber
          n={addonPrice || round(numberOfNights * offering.price, 2)}
        />
      </td>
    </tr>
  )
}

const extraGuests = (
  guest: BookingAddOn,
  hideFrequency = false
): JSX.Element => {
  const { offeringAddOn, offeringAddOnOption, id } = guest
  const { frequency } = offeringAddOn

  const label = offeringAddOnOption?.label || offeringAddOn.label
  const price = offeringAddOnOption?.price || offeringAddOn.price || 0
  return (
    <tr className="fz-13" key={`breakdown-${id}`}>
      <td>
        <span className="fz-12 pl-2">{label}</span>
      </td>
      <td className="pl-3">
        <FormatNumber n={price} />
        {!hideFrequency && frequency === 'DAILY' && price > 0 && (
          <span className="tc-lgray fz-11 pl-2">nightly</span>
        )}
      </td>
    </tr>
  )
}

// taxable and no taxable addons
const extraAddons = (addon: BookingAddOn, basePrice: number): JSX.Element => {
  const { offeringAddOn, offeringAddOnOption, id } = addon
  const { frequency } = offeringAddOn

  const label = offeringAddOnOption?.label || offeringAddOn.label
  const price = offeringAddOnOption?.price || offeringAddOn.price || 0
  return (
    <tr className="fz-13" key={`breakdown-${id}`}>
      <td>{label}:</td>
      <td className="pl-3">
        {offeringAddOn.format === 'FLAT' && <FormatNumber n={price} />}
        {offeringAddOn.format === 'PERCENTAGE' && (
          <>
            <FormatNumber n={price * basePrice} />
            <span className="fz-11 tc-lgray pl-2">
              {offeringAddOn?.price * 100}%
            </span>
          </>
        )}
        {frequency === 'DAILY' && (
          <span className="tc-lgray fz-11"> daily</span>
        )}
      </td>
    </tr>
  )
}

export const BookingBreakdownDetails: React.FC<{
  currentAddons: IcurrentAddons
  guestPrice: PriceComponents
  duration: number
  offering: Offering
  type: 'Room' | 'Package'
}> = ({ guestPrice, currentAddons, offering, type }) => {
  const { roomOffering } = offeringTypes
  const {
    extraNightsAfter,
    extraNightsBefore,
    freeGuests,
    paidGuests,
    noTaxables,
    taxables,
  } = currentAddons

  const { duration, basePrice, offeringPrice, tax, total } = guestPrice

  const addedAdults = sumBy(el => {
    if (el.offeringAddOn.metadata?.subtype === addonMetadata.guest) {
      return 1
    }
    return 0
  }, paidGuests)

  return (
    <div className="mb-3">
      <table data-cy="breakdown">
        <tbody>
          <tr className="fz-15">
            <td data-cy="book-duration">
              <FormatNumber n={total / duration} /> x {duration} nights:
            </td>
            <td className="pl-3" data-cy="total-room">
              <FormatNumber n={total} />
            </td>
          </tr>
          {offering.__typename === roomOffering && (
            <tr>
              <td colSpan={2} className="fz-13 py-1">
                Rate includes {sum([get('room.basic', offering), addedAdults])}{' '}
                adult(s)
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={2}>
              <hr className="my-2" />
            </td>
          </tr>
          <tr className="fz-13">
            <td>Base Price:</td>
            <td className="pl-3">
              <FormatNumber n={basePrice} />
            </td>
          </tr>
          <tr className="fz-13">
            <td>
              <span className="fz-12 pl-2">
                {type === 'Room' ? 'Room' : 'Base'} price
              </span>
            </td>
            <td className="pl-3">
              <FormatNumber n={offeringPrice} />
            </td>
          </tr>

          {freeGuests.map(el => extraGuests(el, type === 'Package'))}
          {paidGuests.map(el => extraGuests(el, type === 'Package'))}

          {extraNightsBefore && extraNights(extraNightsBefore, offering)}
          {extraNightsAfter && extraNights(extraNightsAfter, offering)}
          <tr>
            <td className="aux py-1" colSpan={2} />
          </tr>

          {taxables.map(el => extraAddons(el, basePrice))}
          <tr className="fz-13">
            <td>Tax:</td>
            <td className="pl-3">
              <FormatNumber n={tax} />
            </td>
          </tr>
          <tr>
            <td className="aux py-1" colSpan={2} />
          </tr>
          {noTaxables.map(el => extraAddons(el, basePrice))}
        </tbody>
      </table>
    </div>
  )
}
