import React from 'react'

import { EventCard } from '../../../components'
import { PackageOffering, IPackagesTab } from '../../../types'

import { TabContainer } from './style'

export const Packages: React.FC<IPackagesTab> = ({
  packagesList,
  myEvents,
  groupSize,
}) => {
  if (packagesList.length <= 0) {
    return <p className="fz-13">We currently have no offers</p>
  }

  return (
    <TabContainer>
      {packagesList.map((el: PackageOffering, idx: number) => (
        <EventCard
          idx={idx}
          myEvents={myEvents}
          groupSizeProp={groupSize}
          key={el.id}
          eventData={el}
        />
      ))}
    </TabContainer>
  )
}
