import { useContext } from 'react'
import pick from 'lodash/fp/pick'

import AppContext from '../context'
import { IcurrentUser, IuseCurrentUser } from '../types'

export const useCurrentUser = (): IuseCurrentUser => {
  const { auth, state } = useContext(AppContext)
  const { currentUser } = state
  const { isAuthenticated } = auth

  const userData = pick(
    ['firstName', 'lastName', 'userId', 'avatarUrl', 'role', 'email'],
    currentUser
  ) as IcurrentUser

  return { isAuthenticated, ...userData }
}
