import React from 'react'
import get from 'lodash/fp/get'
import find from 'lodash/fp/find'
import Button from 'react-bootstrap/Button'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { RSVP_PREVIEW_DATA } from '../../graphql'
import { SimpleLoader } from '../../components'
import { Common404 } from '../../components/Error404'
import Arrow from '../../assets/images/icons/arrow.svg'
import { useCurrentUser } from '../../common'
import {
  MyEvent,
  EventComponent,
  EventComponentStatus,
  Template,
} from '../../types'

import { RSVPcontainer } from './style'
import { EventSegments, RSVPmap } from './components'
import { exampleData } from './RSVPpreviewExampleData'
import { Template1, Template2 } from './components/templates'

export const RSVPpreview: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>()
  const { firstName, lastName } = useCurrentUser()
  const variables = { id: eventId }
  const { data, loading } = useQuery(RSVP_PREVIEW_DATA, { variables })
  const event: MyEvent = get('me.myEvents[0]', data)

  if (loading) return <SimpleLoader />
  else if (!data) {
    return (
      <Common404 text="No preview data" link={`/event/${eventId}/settings`} />
    )
  }

  const { Available } = EventComponentStatus
  const paidComponents = event.components.filter(el => el.status === Available)
  if (paidComponents.length <= 0) {
    return <Common404 text="Location TBD" hideLink />
  }

  const mainComponent =
    find({ main: true }, paidComponents) || paidComponents[0]
  const mainProvider = mainComponent?.offering.provider

  const { mainImage } = event
  const template = event.invitationTemplate?.template as Template

  const eventData = {
    id: event.id,
    date: mainComponent.date,
    duration: mainComponent.duration,
    name: event.name,
    description: event.description,
    mainImage,
    video: event.video,
    host: {
      firstName,
      lastName,
      avatarUrl: '',
    },
    brideFirstName: event.brideFirstName,
    brideLastName: event.brideLastName,
    groomFirstName: event.groomFirstName,
    groomLastName: event.groomLastName,
  }

  const renderTemplate = (children: React.ReactElement) => {
    if (template === 'template_2') {
      return (
        <Template2
          event={eventData}
          guest={{
            firstName: 'firstName',
            lastName: 'lastName',
            email: 'example@gmail.com',
            status: 'accepted',
          }}
          plusOne={2}
          mainProvider={mainProvider}
          kidsAllowed={false}
        >
          {children}
        </Template2>
      )
    }

    // by default we render template_1
    return (
      <Template1
        event={eventData}
        guest={{
          firstName: 'firstName',
          lastName: 'lastName',
          email: 'example@gmail.com',
          status: 'accepted',
        }}
        plusOne={2}
        mainProvider={mainProvider}
        kidsAllowed={false}
      >
        {children}
      </Template1>
    )
  }

  return (
    <RSVPcontainer className="pt-0 pt-sm-4 container">
      {renderTemplate(
        <>
          <Button className="rounded-pill">View Event</Button>
          <h6
            className="mx-auto mt-4 position-relative fwsb"
            style={{ maxWidth: '350px' }}
          >
            Book your room, get updates
            <br />& chat with the group
            <img
              src={Arrow}
              alt="->"
              className="arrow-rsvp h-auto position-absolute"
              style={{
                width: '40px',
                transform: 'scaleX(-1) rotate(-40deg)',
                top: '-28px',
                right: '65px',
              }}
            />
          </h6>
        </>
      )}

      {/** if there are paid components the default components are replaced by the paid components */}
      <EventSegments
        eventId={eventId}
        eventName={event.name}
        guest={{
          firstName: 'firstName',
          lastName: 'lastName',
          email: 'email@gmail.com',
          status: 'accepted',
        }}
        eventComponents={
          paidComponents.length > 0
            ? paidComponents
            : (exampleData.eventComponents as EventComponent[])
        }
      />
      {/** just to remove the Maybe type */}
      <RSVPmap
        components={
          paidComponents.length > 0
            ? paidComponents
            : (exampleData.eventComponents as EventComponent[])
        }
      />
    </RSVPcontainer>
  )
}
