import styled from 'styled-components'

import { salmon } from '../../styles/themeColors'

export const HeroSection = styled.section`
  background-color: ${salmon};
  display: flex;
  max-height: 500px;
  overflow-y: hidden;
  position: relative;
  & .hero {
    display: block;
    margin: 0 auto;
    max-width: 94%;
  }
  & h1,
  & div {
    width: 50%;
  }
  & span {
    bottom: -1px;
    display: block;
    left: 0;
    position: absolute;
    width: 100%;
  }
  @media (max-width: 710px) {
    flex-wrap: wrap;
    & h1,
    & div {
      width: 100%;
    }
    & h1 {
      font-size: 80px !important;
    }
    & div img {
      max-width: 100%;
    }
  }
`
