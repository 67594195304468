import React, { useState, useEffect } from 'react'
import set from 'lodash/fp/set'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import { useQuery } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'

import Logo from '../../assets/images/logo.png'
import { Notification } from '../../types'
import { useToast } from '../../common'
import { GET_NOTIFICATIONS, NOTIFICATIONS_SUBSCRIPTION } from '../../graphql'
import { myHub } from '../../common/constants'
import { HowWeWork } from '../../components'

import MyEvents from './MyEvents'
import { Notifications } from './components'
import { MobileNavbar } from './MobileNavbar'

import { UserOptions } from '.'

export const CustomerNavbar: React.FC = () => {
  const { toast } = useToast()
  const { data, subscribeToMore } = useQuery(GET_NOTIFICATIONS)
  const [openHowWeWork, setOpenHowWeWork] = useState(false)

  useEffect(() => {
    subscribeToMore({
      document: NOTIFICATIONS_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev
        }
        const newData: Notification = subscriptionData.data?.notificationsPush
        const oldData: Notification[] = prev?.notifications?.entries || []

        toast({ content: newData, delay: 10000 }) // 10s
        if (newData.volatile) {
          return prev
        }
        localStorage.setItem('newNotification', 'newNotification')
        return set('notifications.entries', [newData, ...oldData], prev)
      },
    })
    /**
     * if I add the 'toast' dependency the subscribeToMore is triggered twice
     * therefore when a notification is sent it appears twice on the screen
     * I have to investigate how to check if subscribeToMore is already configured
     * although it's not necessary because the toast function doesn't change at all so it's not important
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeToMore])

  const notificationsList: Notification[] =
    data?.notifications?.entries?.filter(
      (i: Notification) => !i.volatile && !i.dismissed
    ) || []

  return (
    <Navbar
      bg="white"
      fixed="top"
      className="py-1 border-b"
      data-cy="private-nav"
    >
      <MobileNavbar openHowWeWork={setOpenHowWeWork} list={notificationsList} />
      <Container className="pl-3 pr-2 d-none d-lg-flex">
        <Navbar.Brand as={Link} to="/">
          <img src={Logo} alt="Kiss and Tell" style={{ maxWidth: '149px' }} />
        </Navbar.Brand>
        <Nav>
          <Nav.Link className="tc-gray fwsb" to={myHub} as={Link}>
            My Hub
          </Nav.Link>
          <Nav.Link
            data-cy="how-it-works"
            className="tc-gray fwsb"
            onClick={() => setOpenHowWeWork(true)}
          >
            How We Work
          </Nav.Link>
          <HowWeWork
            open={openHowWeWork}
            close={() => setOpenHowWeWork(false)}
            modal
          />
          <Nav.Link
            data-cy="navbar-locations"
            to="/marketplace"
            as={Link}
            className="tc-gray fwsb"
          >
            Wedding Destinations
          </Nav.Link>
          <Nav.Link to="/blog" as={Link} className="tc-gray fwsb">
            Inspiration
          </Nav.Link>
        </Nav>
        <Nav className="ml-auto mr-2">
          <MyEvents />
        </Nav>
        <Nav className="mr-2 d-flex">
          <UserOptions />
          <Notifications list={notificationsList} />
        </Nav>
      </Container>
    </Navbar>
  )
}
