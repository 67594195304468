import React, { useState } from 'react'
import { MdChevronLeft } from 'react-icons/md'

import { MobileSubMenu } from './style'

interface ISubmenu {
  title: string
  expandedSubmenu: React.Dispatch<React.SetStateAction<boolean>>
}

export const Submenu: React.FC<ISubmenu> = ({
  title,
  children,
  expandedSubmenu,
}) => {
  const [subMenuList, openSubMenuList] = useState(false)

  const manageEvents = (ev: React.MouseEvent) => {
    ev.preventDefault()
    expandedSubmenu(!subMenuList)
    openSubMenuList(!subMenuList)
  }

  return (
    <MobileSubMenu className={subMenuList ? 'open-submenu' : 'close-submenu'}>
      <a className="title-submenu" onClick={manageEvents}>
        <MdChevronLeft size={22} /> {title}
      </a>
      <div className="link-list">{children}</div>
    </MobileSubMenu>
  )
}
