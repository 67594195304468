import React, { useEffect } from 'react'
import get from 'lodash/fp/get'
import map from 'lodash/fp/map'
import sum from 'lodash/fp/sum'
import flow from 'lodash/fp/flow'
import orderBy from 'lodash/fp/orderBy'
import filter from 'lodash/fp/filter'
import partition from 'lodash/fp/partition'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { Helmet } from 'react-helmet'
import { useParams, useLocation } from 'react-router-dom'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'

import roomIcon from '../../assets/images/icons/room.svg'
import {
  DisplayRoomBlockDates,
  FullSlider,
  SimpleLoader,
  SmallLoader,
} from '../../components'
import { Common404 } from '../../components/Error404'
import { GET_INVITATION_DATA, GET_LOCATIONS_BY_IDS } from '../../graphql'
import {
  BestRoom,
  EventComponent,
  InvitationInfo,
  Provider,
  RoomOffering,
  EventComponentStatus,
} from '../../types'
import { useMoveToTop, getImages } from '../../common'
import { offeringTypes } from '../../common/constants'
import { LocationMap, LocationRates } from '../Locations/components'
import { Details } from '../Locations/style'

import { RoomsContainer } from './style'
import {
  RoomCardSelection,
  EventCardSelection,
  getSooner,
  getFurthest,
} from './components'

export const ComponentsSelection: React.FC = () => {
  useMoveToTop()
  const { pathname } = useLocation()
  const { Available } = EventComponentStatus
  const { roomOffering, packageOffering } = offeringTypes
  const viewType = pathname.includes('events') ? packageOffering : roomOffering

  const { eventId, providerId } = useParams<{
    eventId: string
    providerId: string
  }>()
  const [getLocation, { data: locationData, loading: loadingProvider }] =
    useLazyQuery(GET_LOCATIONS_BY_IDS)
  const { data, loading } = useQuery(GET_INVITATION_DATA, {
    variables: { eventId },
  })

  useEffect(() => {
    if (providerId && !loadingProvider && !locationData) {
      getLocation({ variables: { ids: [providerId] } })
    }
  }, [providerId, getLocation, locationData, loadingProvider])

  if (loading) return <SimpleLoader />
  if (!data && !loading) return <Common404 text="Data not available" />

  const invitation: InvitationInfo = get('me.invitation', data)
  const components = flow(
    filter(
      (el: EventComponent) =>
        el.status === Available && el.offering.provider.id === providerId
    ),
    orderBy(['offering.price'], ['asc']),
    partition((el: EventComponent) => el.offering.__typename === roomOffering)
  )(invitation?.components)

  const [rooms, packages] = components

  // provider
  const provider: Provider = get('providers[0]', locationData)
  const images = getImages(provider?.images, '1280x500')

  const sleeps = flow(
    map((el: EventComponent) => {
      const room = el.offering as RoomOffering
      return el.capacity * room?.room?.occupancy
    }),
    sum
  )(rooms)
  const bestRoom: BestRoom = {
    price: rooms[0]?.offering?.price,
    sleeps,
  }
  const airportData = {
    code: provider?.airportCode,
    time: provider?.timeToAirport,
  }
  const cutOff = provider?.metadata?.cut_off

  const numberOfRooms = flow(
    map((el: EventComponent) => el?.capacity),
    sum
  )(rooms)
  const mm = provider?.bookAllRequired ? 0 : 3
  const sooner = getSooner(rooms)
  const furthest = getFurthest(rooms)
  return (
    <Container className="py-3">
      <Helmet>
        <title>{invitation.name}</title>
        <meta
          name="description"
          content={
            invitation?.description || 'Kiss & Tell Rooms/Events selection'
          }
        />
      </Helmet>
      <h2 className="fwsb fz-35">{invitation.name}</h2>
      <FullSlider images={images} small />
      {loadingProvider && (
        <div className="text-center">
          <SmallLoader />
        </div>
      )}
      {provider && (
        <Row noGutters className="mt-2 mb-3">
          <Col xs={12} lg={7}>
            <h3 className="fwb fz-24">
              <span className="text-primary fz-14 d-block fwsb">
                {provider.location}
              </span>
              <span className="tc-gray d-block">{provider.name}</span>
            </h3>
            {provider.address && (
              <p className="fz-12 m-0">{provider.address}</p>
            )}

            <LocationRates
              className="mt-4 mb-0 mb-md-3"
              bestRoom={bestRoom}
              airport={airportData}
              groupSize={0}
              cutOff={cutOff}
            />
          </Col>
          <Col xs={12} lg={5}>
            <LocationMap
              provider={provider}
              style={{ height: '100%', minHeight: '200px' }}
            />
          </Col>
        </Row>
      )}

      {viewType === roomOffering && (
        <div className="pt-0 pt-lg-4">
          <p className="text-primary fz-24 fwsb mb-2">Select your Room</p>
          <DisplayRoomBlockDates
            mm={mm}
            checkin={sooner?.date}
            checkout={furthest?.date}
          />
          {provider?.cancellationPolicy && (
            <p className="tc-lgray fz-13 mw-1200 pr-0 pr-md-5 mb-0 pb-4">
              {provider.cancellationPolicy}
            </p>
          )}
          <div className="d-flex align-content-center mb-4">
            <img src={roomIcon} alt="# of rooms" id="room-icon-provider" />
            <p className="fwsb fz-16 mb-0 ml-1">
              Total # of Block Rooms
              <span
                className="fz-14 d-block tc-lgray fwn"
                style={{ lineHeight: '1' }}
              >
                {numberOfRooms}
              </span>
            </p>
          </div>

          <RoomsContainer>
            {rooms.map(el => (
              <RoomCardSelection key={el.id} component={el} />
            ))}
          </RoomsContainer>
        </div>
      )}

      {viewType === packageOffering && (
        <div className="pt-0 pt-lg-4">
          <p className="fwsb fz-16">
            See the details for our event, RSVP, explore the venue images and
            mark your calendar!
          </p>

          {packages.map(el => (
            <EventCardSelection
              key={el.id}
              component={el}
              eventName={invitation.name}
            />
          ))}
        </div>
      )}

      {provider && (
        <Details className="mt-5">
          <div
            className="location-details-description mt-2"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: provider.description as string,
            }}
          />
        </Details>
      )}
    </Container>
  )
}
