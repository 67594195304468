import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

import { useCurrentUser, useQueryParams, useMoveToTop } from '../../../common'
import { VENDOR } from '../../../common/constants'

import Quotes from './components/Quotes'
import SendMessages from './components/SendMessages'
import { ChatBanner } from './components'

export const ChatWithVendor: React.FC = () => {
  useMoveToTop()
  const { role } = useCurrentUser()
  const urlParam = useQueryParams()
  const negotiationObjectPath =
    role === VENDOR ? 'me.negotiation' : 'me.myEvents[0].negotiation'

  const { eventId, providerId } = useParams<{
    eventId: string
    providerId: string
  }>()

  return (
    <>
      <Helmet>
        <title>Kiss & Tell - Chat with vendor</title>
        <meta
          name="description"
          content="Chat with vendor about the event and negotiate the terms for the event."
        />
      </Helmet>
      <ChatBanner />
      <Container>
        {urlParam?.payment && (
          <Row className="pt-3">
            <Col>
              <Alert
                className="text-center"
                variant={`${
                  urlParam.payment === 'success' ? 'success' : 'danger'
                }`}
              >
                {urlParam.payment === 'success'
                  ? 'Your payment has been successfully completed.'
                  : 'There was a problem completing your payment. Please try again.'}
              </Alert>
            </Col>
          </Row>
        )}
        <Row className={`${urlParam?.payment ? 'pt-0' : 'pt-3'}`}>
          <Col lg={5} className="fz-16">
            <Quotes
              path={negotiationObjectPath}
              eventId={eventId}
              providerId={providerId}
              role={role}
            />
          </Col>
          <Col lg={7}>
            <SendMessages
              path={negotiationObjectPath}
              eventId={eventId}
              providerId={providerId}
              role={role}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}
