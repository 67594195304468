export const KTUserToken = 'KTUser'
export const AUTHENTICATED = 'AUTHENTICATED'
export const UNAUTHENTICATED = 'UNAUTHENTICATED'

export const CUSTOMER = 'CUSTOMER'
export const VENDOR = 'VENDOR'

// booking
export const guestStatuses = {
  created: 'created',
  sent: 'sent',
  accepted: 'accepted',
  declined: 'declined',
  booked: 'booked',
}

// youtube
export const makeoutSessionsPlaylist = 'PLi445m17RQEZYabaoc1XOHzICpklqdqF1'
export const televisionSegmentsPlaylist = 'PLi445m17RQEYdOcaHv3JL38NFWeaNR9NW'
export const YoutubeKey = 'AIzaSyAHx5ePmwaYsdNXDZc7Bxk1PI77XLC8nhg'

// google maps
export const MAPS_DEV_API_KEY = 'AIzaSyDkRFSHx4gQ4F95tAan6-spENBPPV7Kq_M'
export const MAPS_PROD_API_KEY = 'AIzaSyATkbb_2KQ9JXihBnnt7qfPKAaPlPPhC7I'

// google calendar
export const CALENDAR_PROD_CLIENT_ID =
  '1056678656907-tlrd47ouqpehrpu03a0j4af9k267vtnk.apps.googleusercontent.com'
export const CALENDAR_DEV_CLIENT_ID =
  '312570346134-arkbme35jje46lt7jl47s1l8begjc389.apps.googleusercontent.com'
export const CALENDAR_DEV_KEY = 'AIzaSyDu4DpEP98fTdpzSfnBcgLqxA5RNraduG0'
export const CALENDAR_PROD_KEY = 'AIzaSyAZHs9vBgEkn1mnHN4qTA6UehlxHgIn2lk'

// server urls
export const IMAGES = {
  bucket: 'kt.images',
  url: 'https://d1vrb4z9tps496.cloudfront.net',
}
export const FILESTACK_KEY = 'AYzrpSdpTxKBZIr1dcIIWz'
export const STRIPE_KEY_DEV = 'pk_test_0lPIct8X6PbbpxQ7c0pPb3v6'
export const STRIPE_KEY = 'pk_live_RtxgGjGUhq9adzGokbqCKEai'

// offerings
export const offeringTypes = {
  ROOM: 'ROOM',
  PACKAGE: 'PACKAGE',
  packageOffering: 'PackageOffering',
  roomOffering: 'RoomOffering',
}

export const addonMetadata = {
  freeGuest: 'extra_guest_free',
  guest: 'extra_guest_adult',
  kid: 'extra_guest_kid',
  nightsBefore: 'extra_nights_before',
  nightsAfter: 'extra_nights_after',
}

// redirect urls
export const myHub = '/hub'

// Config
export const characterLimit = 500 // comments in posts
export const APP_URL_DEV = 'http://localhost:3000'
export const APP_URL = 'https://kissandtell.com'
