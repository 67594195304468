import styled from 'styled-components'

import {
  gray,
  darkGray,
  lightGray,
  lightBackground,
} from '../../styles/themeColors'

export const Img = styled.img`
  max-width: 300px;
  position: relative;
  top: -1px;
  width: 100%;
  &.full {
    max-width: 100%;
  }
`

export const EventButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & .btn-event-selector {
    align-items: center;
    background-color: white;
    border: 1px solid ${lightGray};
    border-radius: 25px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    margin: 0 5px 8px 0;
    min-height: 31px;
    padding: 1px 8px;
    transition: all 0.2s ease-in-out;
    & svg {
      display: inline-block;
      margin: 0 5px 0 0;
      position: relative;
      top: -2px;
    }
    &:hover {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
      transition: all 0.2s ease-in-out;
    }
  }
`

export const HeadPost = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 0 1rem 0;
  position: relative;
  & div.info {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 10px;
  }
`

export const Postcontent = styled.p`
  color: ${darkGray};
  white-space: pre-wrap;
  & span {
    display: block;
    min-height: 19px;
    min-width: 1px;
  }
`

export const MainActions = styled.p`
  align-items: center;
  border-bottom: 1px solid ${lightGray};
  border-top: 1px solid ${lightGray};
  display: flex;
  color: ${gray};
  justify-content: flex-start;
  & span {
    transition: all 0.2s linear;
    &:hover {
      background-color: ${lightBackground};
      transition: all 0.2s linear;
    }
  }
`

export const NewComment = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  & form {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 90%;
  }
  & textarea {
    background-color: #f7f7f8;
    border: 1px solid ${lightGray};
    border-radius: 20px;
    outline: none;
    overflow: hidden;
    padding: 5px 20px 5px 10px;
    resize: none;
    width: 100%;
  }
`

export const ViewReplies = styled.p`
  font-size: 12px;
  margin: 0;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
  & .ico {
    transform: rotate(180deg);
  }
  & span {
    color: #a4a4a5;
    font-size: 11px;
  }
`

export const EventSelectorContainer = styled.div`
  background-color: white;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  padding: 7px 0 2px 0;
`
