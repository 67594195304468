import React from 'react'
import flow from 'lodash/fp/flow'
import orderBy from 'lodash/fp/orderBy'
import groupBy from 'lodash/fp/groupBy'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper/core'

import { getImageUrl, sortPackagesBySubEvent } from '../../../common'
import { EventComponent } from '../../../types'
import { offeringTypes } from '../../../common/constants'

import { MainProviderImage } from './style'

import { EventComponentOffering } from '.'

import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'

SwiperCore.use([Navigation])

export const EventComponentsSlider: React.FC<{
  eventComponents: EventComponent[]
  isMyEvent: boolean
}> = React.memo(({ eventComponents, isMyEvent }) => {
  const { roomOffering, packageOffering } = offeringTypes
  const filtered = groupBy(el => el.offering.__typename, eventComponents) // 1 groupBy instead of 2 filters

  const { provider } = eventComponents[0].offering
  const providerImage = getImageUrl(provider.mainImage, '400x225')

  const orderedPackages = flow(
    pkgs => sortPackagesBySubEvent(pkgs),
    orderBy(['date'], ['asc']) // TODO: refactor to not do too many sorts
  )(filtered[packageOffering]) as EventComponent[]
  return (
    <>
      {orderedPackages?.map(el => (
        <EventComponentOffering
          key={`package-${el.id}`}
          eventComponent={el}
          isMyEvent={isMyEvent}
          showMainIcon
        />
      ))}

      <Swiper style={{ width: '100%' }} navigation pagination spaceBetween={10}>
        {filtered[roomOffering]?.length > 0 && (
          <SwiperSlide>
            <MainProviderImage
              style={{
                backgroundImage: `url(${providerImage})`,
                marginTop: '5px',
              }}
            >
              <h4 className="text-center">Guest Rooms</h4>
            </MainProviderImage>
          </SwiperSlide>
        )}

        {filtered[roomOffering]?.map(el => (
          <SwiperSlide key={`slide-rooms-${el.id}`}>
            <EventComponentOffering eventComponent={el} isMyEvent={isMyEvent} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
})
