import React, { useState, useEffect, useRef } from 'react'
import flow from 'lodash/fp/flow'
import unset from 'lodash/fp/unset'
import { useHistory } from 'react-router-dom'
import { MdSearch } from 'react-icons/md'
import { Wrapper, Status } from '@googlemaps/react-wrapper'

import { checkEnv, getCoords, getImageUrl } from '../../../common'
import { MAPS_DEV_API_KEY, MAPS_PROD_API_KEY } from '../../../common/constants'
import {
  Provider,
  GoogleMap,
  GoogleMarker,
  GoogleLatLng,
  IFilterInputs,
  IProviderListMap,
} from '../../../types'
import { makeSearchUrl } from '../LocationFunctions'

import { HideMap, UpdateMap } from './style'

const adjustMapZoom = (map: GoogleMap, providers: Provider[]) => {
  const latlngbounds = new window.google.maps.LatLngBounds()
  providers.forEach(provider => {
    if (provider.coord) {
      const [lat, lng] = getCoords(provider.coord)
      latlngbounds.extend(new window.google.maps.LatLng({ lat, lng }))
    }
  })
  map.fitBounds(latlngbounds)
}

const makeInfoWindow = (provider: Provider) => {
  const image = getImageUrl(provider.mainImage, '300x168')
  const { location, name, address, url } = provider
  return `<a href="/locations/${url}" target="_blank" style="color: #4a4a4a; text-decoration: none;">
    <div class="pl-1">
      <h5 style="width: 300px">
        <span class="text-primary fz-12 d-block">${location}</span>
        ${name}
      </h5>
      <img src="${image}" alt="${name}" />
      <p style="width: 300px" class="pt-2 fz-14 m-0">${address}</p>
    </div>
  </a>
  `
}

const goToProvider = (id: string) => {
  const element = document.getElementById(`card-${id}`)
  if (element) {
    window.scroll({
      top: element.offsetTop - 70,
      left: 0,
      behavior: 'smooth',
    })
  }
}

const Map: React.FC<
  IProviderListMap & {
    setLatLng: React.Dispatch<React.SetStateAction<GoogleLatLng | undefined>>
    latLng?: GoogleLatLng
  }
> = ({ providers, urlParams, latLng, setLatLng }) => {
  const [map, setMap] = useState<GoogleMap>()
  const [autoZoom, setAutoZoom] = useState(true)
  const [prevUrlParams, setPrevUrlParams] = useState('init')
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center: { lat: 20.3543899, lng: -87.6360736 },
          zoom: 8,
          mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        })
      )
    }

    const markers: GoogleMarker[] = []
    const infowindow = new window.google.maps.InfoWindow() // this variable is declared outside the loop to open only one infowindow at a time
    if (map && providers.length > 0) {
      providers.forEach(provider => {
        if (provider.coord) {
          const [lat, lng] = getCoords(provider.coord)
          const marker = new window.google.maps.Marker({
            position: { lat, lng },
            map,
            title: provider.name,
            optimized: true,
          })
          markers.push(marker)

          marker.addListener('click', () => {
            infowindow.setContent(makeInfoWindow(provider))
            infowindow.open({ anchor: marker, map, shouldFocus: false })
            goToProvider(provider.id) // scroll to the card
          })
        }
      })

      if (autoZoom) {
        adjustMapZoom(map, providers)
        setAutoZoom(false)
      }
    }

    return () => {
      // this is called when the component is unmounted to remove the old markers
      // if I don't do this, the markers will stay on the map even after the provider list changes
      markers.forEach(marker => marker.setMap(null))
    }
  }, [map, providers, autoZoom])

  if (map && prevUrlParams !== JSON.stringify(urlParams)) {
    setPrevUrlParams(JSON.stringify(urlParams))
    setAutoZoom(true)
  }

  if (map) {
    map.addListener('dragend', () => !latLng && setLatLng(map.getCenter()))
  }

  return <div className="w-100 h-100" ref={ref} />
}

const ProviderListMap: React.FC<IProviderListMap> = ({
  providers,
  urlParams,
  showGoogleMap,
}) => {
  const history = useHistory()
  const { develop } = checkEnv()
  const [latLng, setLatLng] = useState<GoogleLatLng>()
  const apiKey = develop ? MAPS_DEV_API_KEY : MAPS_PROD_API_KEY
  const render = (status: Status) => <div className="text-center">{status}</div>

  const handleUpdateMap = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault()
    const url = makeSearchUrl(history.location.pathname, {
      ...flow(unset('where'), unset('what'))(urlParams), // remove the where and what params to use the new latlng for the search
      lat: `${latLng?.lat()}`,
      lng: `${latLng?.lng()}`,
    } as IFilterInputs)

    history.push(url)
    setLatLng(undefined)
  }
  return (
    <section className="providers-map">
      <div className="position-relative h-100">
        {showGoogleMap && (
          <HideMap
            onClick={() => showGoogleMap(false)}
            type="button"
            className="d-none d-lg-inline-block"
          >
            Hide Map
          </HideMap>
        )}

        {latLng && (
          <UpdateMap type="button" onClick={handleUpdateMap}>
            <MdSearch /> search in this area
          </UpdateMap>
        )}

        <Wrapper apiKey={apiKey} render={render}>
          <Map
            latLng={latLng}
            setLatLng={setLatLng}
            providers={providers}
            urlParams={flow(unset('lat'), unset('lng'))(urlParams)}
          />
        </Wrapper>
      </div>
    </section>
  )
}

export default React.memo(ProviderListMap)
