import React, { lazy, memo, useEffect, Suspense } from 'react'
import ReactGA from 'react-ga4'
import { withRouter, useLocation } from 'react-router-dom'

import NavBar from '../Navbar/Navbar'
import HomeNavbar from '../Navbar/HomeNavbar'
import { Footer } from '../../pages/Home/Footer'
import { IParnershipTrack } from '../../types'
import {
  addRefId,
  getRefId,
  saveRefId,
  useCurrentUser,
  useQueryParams,
} from '../../common'

const FloatingButton = lazy(() => import('./components/FloatingButton'))

const Layout: React.FC = ({ children }) => {
  const location = useLocation()
  const { isAuthenticated } = useCurrentUser()
  const params = useQueryParams()

  useEffect(() => {
    ReactGA.set({ page: location.pathname })
  }, [location.pathname])

  useEffect(() => {
    const refId = params?.refId || params?.refid
    if (!refId) return

    // to not add refId on every rerender but add it again if the user
    // closes/leaves the page or returns from a new referrer
    if (getRefId() === `${refId}${document.referrer}`) return
    saveRefId(`${refId}${document.referrer}`)

    const newRefId: IParnershipTrack = {
      refId,
      date: new Date().toISOString(),
      source: document?.referrer,
      destination: location.pathname,
    }

    addRefId(newRefId)
  }, [params, location.pathname])

  if (params?.preview) {
    return <>{children}</>
  }

  const bg = location.pathname === '/' ? 'bg-white' : 'bg-lgray'
  return (
    <>
      {isAuthenticated ? (
        <NavBar />
      ) : (
        <>
          <Suspense fallback={<></>}>
            <FloatingButton />
          </Suspense>
          <HomeNavbar />
        </>
      )}
      <main className={bg}>{children}</main>
      <Footer />
    </>
  )
}

export default memo(withRouter(Layout))
