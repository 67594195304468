import React from 'react'
import reduce from 'lodash/fp/reduce'
import get from 'lodash/fp/get'

import { sumItems } from '../../eventFunctions'
import { FormatNumber, SimpleTooltip } from '../../../../components'
import { useCurrentUser } from '../../../../common'
import { VENDOR } from '../../../../common/constants'
import { Quote } from '../../../../types'

import NegotiationQuote from './NegotiationQuote'
import { QuoteListContainer } from './style'

type TypeElement = 'PackageOffering' | 'RoomOffering'

const QuotesList: React.FC<{ quotes: Quote[] }> = ({ quotes }) => {
  const { role } = useCurrentUser()

  const totals = reduce(
    (
      accum: { RoomOffering: number; PackageOffering: number },
      element: Quote
    ) => {
      const resp = accum // to not reasign the param
      const { offering } = element.eventComponent
      const elementType = offering.__typename as TypeElement
      const itemPrices = sumItems(element.items)
      resp[elementType] += itemPrices
      return resp
    },
    { RoomOffering: 0, PackageOffering: 0 },
    quotes
  )

  return (
    <QuoteListContainer className="shadow-sm mt-3">
      <p className="pb-3 m-0 text-center fwsb fz-18">
        {role === VENDOR ? 'Offerings' : 'Quotes'}
      </p>

      {quotes.map(quote => (
        <NegotiationQuote quote={quote} key={get('eventComponent.id', quote)} />
      ))}

      {totals.PackageOffering > 0 && (
        <p className="fz-14 my-0">
          Event Total: <FormatNumber n={totals.PackageOffering} />
        </p>
      )}
      {totals.RoomOffering > 0 && (
        <p className="fz-14 my-0">
          Guest Room Total: <FormatNumber n={totals.RoomOffering} />
          <SimpleTooltip
            className="ml-2"
            text="Guests pay for their own rooms to offset this price."
            id="guest-room-total"
          />
        </p>
      )}
      <p className="fwsb my-2">
        Grand Total:{' '}
        <FormatNumber n={totals.PackageOffering + totals.RoomOffering} />
      </p>
    </QuoteListContainer>
  )
}

export default React.memo(QuotesList)
