import React from 'react'
import flow from 'lodash/fp/flow'
import trim from 'lodash/fp/trim'
import toLower from 'lodash/fp/toLower'
import split from 'lodash/fp/split'
import kebabCase from 'lodash/fp/kebabCase'

import { Maybe } from '../types'

export const replaceLineBreaksWithBR = (text: string): JSX.Element[] => {
  const splitText = split(/\r\n|\n|\r/gm, text)

  return splitText.map((el, idx) => (
    <React.Fragment key={kebabCase(el)}>
      {idx > 0 && <br />}
      {el}
    </React.Fragment>
  ))
}

export const shortenLink = (match: string): string => {
  const shortLink = match // pending service

  return `<a href="${match}" target="_blank" rel="noopener noreferrer">${shortLink}</a>`
}

// we cant use this functions with Maybe<string> so we need to cast it to string
export const cleanString = (text: Maybe<string> = ''): string =>
  flow(trim, toLower)(text as string)

export const textContainsEmoji = (text?: Maybe<string>): boolean => {
  if (!text) return false
  const emojiRegex =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E6}-\u{1F1FF}]/u
  return emojiRegex.test(text)
}

export const smsLimit = (text?: Maybe<string>): number => {
  // more info: https://www.twilio.com/docs/glossary/what-sms-character-limit
  if (textContainsEmoji(text)) return 70
  if (text?.length && text.length > 160) return 153
  return 160
}
