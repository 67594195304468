import React from 'react'

import { useCurrentUser } from '../../common'
import { VENDOR } from '../../common/constants'

import { VendorNarbar, CustomerNavbar } from '.'

const NavBar: React.FC = () => {
  const { role } = useCurrentUser()

  if (role === VENDOR) {
    return <VendorNarbar />
  }

  return <CustomerNavbar />
}

export default React.memo(NavBar)
