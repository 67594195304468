import styled from 'styled-components'

import { darkGray } from '../../../styles/themeColors'

export const CTACardContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  height: 260px;
  margin: 0 10px 0 0;
  max-width: 200px;
  width: 20%;
  & .photo {
    background-color: ${darkGray};
    background-position: center;
    background-size: cover;
    border-radius: 5px 5px 0 0;
    height: 200px;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;
    & svg {
      bottom: -45px;
      position: absolute;
      right: calc((100% - 250px) / 2);
    }
  }
  & .plus-icon {
    margin: -20px 0 0 0;
    text-align: center;
    position: relative;
    & span {
      background-color: white;
      border-radius: 50%;
      display: inline-block;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    margin: 0;
    width: 45%;
  }
`

export const CreateEventCardContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0;
  width: 100%;
  &.create-bg {
    height: auto;
    max-width: 400px;
    & > div {
      padding: 0 10px;
    }
  }
  &.create-sm {
    height: 260px;
    max-width: 200px;
    & > div {
      padding: 0 0 0 20px;
    }
    & .arrow-create {
      left: -30%;
      position: relative;
      top: 0;
      transform: rotate(-35deg);
    }
    h2 {
      padding: 10px 0 0 0;
    }
    @media (max-width: 767px) {
      height: auto;
      max-width: 100%;
      & .text-right {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin: 0 15% 0 auto;
        & .arrow-create {
          left: 0;
          order: 2;
          transform: rotate(30deg);
        }
        & h2 {
          order: 1;
          width: 100%;
        }
      }
    }
  }
`

export const SwiperContainer = styled.div`
  position: relative;
  width: calc(100% - 210px);
  &.noEvents {
    @media (min-width: 768px) {
      width: calc(100% - 410px) !important;
    }
  }
  & .swiper-next,
  & .swiper-prev {
    align-items: center;
    background-color: white;
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    z-index: 2;
  }
  & .swiper-next {
    right: -10px;
  }
  & .swiper-prev {
    left: -8px;
  }
  @media (max-width: 500px) {
    width: 50%;
  }
`

export const SlideCard = styled.article`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  height: 260px;
  margin: 0 0 0 auto;
  max-width: 200px;
  overflow: hidden;
  position: relative;
  width: 100%;
  & .info {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 20%,
      rgba(0, 0, 0, 0.7) 100%
    );
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    & div {
      align-items: flex-end;
      bottom: 0;
      display: flex;
      color: white;
      flex-wrap: wrap;
      font-size: 13px;
      padding: 0 10px;
      position: absolute;
      right: 0;
      width: 100%;
      & h2 {
        font-size: 14px;
        font-weight: 600;
        width: 100%;
      }
      & p {
        width: 100%;
      }
    }
  }
  @media (max-width: 500px) {
    margin: 0 auto;
    max-width: 100%;
  }
`

export const Details = styled.div`
  width: 100%;
  & .details-description * {
    font-size: 14px !important;
  }
  & .icons {
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: flex-start;
    padding-bottom: 10px;
    & div {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0 30px 0 0;
      & img {
        height: 50px;
        width: 50px;
      }
      & p {
        margin: 0;
      }
    }
  }
  @media (max-width: 500px) {
    & .icons {
      flex-wrap: wrap;
      justify-content: space-between;
      & div {
        justify-content: flex-start;
        margin: 0 10px 0 0;
        width: 45%;
      }
    }
  }
`
