import React, { useState } from 'react'
import { MdClear } from 'react-icons/md'

import EventIcon from '../../../../assets/images/icons/eventicon.svg'
import HostNegotiation from '../../../../assets/images/HostNegotiationBG.jpg'
import VendorNegotiation from '../../../../assets/images/VendorNegotiationBG.jpg'
import { useCurrentUser } from '../../../../common'
import { VENDOR } from '../../../../common/constants'

import { EventBuilderBanner } from './style'

export const ChatBanner: React.FC = () => {
  const { role } = useCurrentUser()
  const [hideBanner, setHideBanner] = useState(
    sessionStorage.getItem('event-builder-banner-chat')
  )

  const closeModal = () => {
    setHideBanner('hide')
    sessionStorage.setItem('event-builder-banner-chat', 'hide')
  }

  if (hideBanner) {
    return <></>
  }

  const hostText = `Confirm availability, pricing or additional services directly with vendors and place your deposit to book!`

  const vendorText = `Confirm availability, pricing or offer additional details 
    here in your chat with the customer.  When you’re ready, accept each component 
    to generate a quote, allowing the host to pay their deposit.`

  return (
    <EventBuilderBanner
      className={`shadow-sm ${
        role === VENDOR ? 'VendorNegotiation' : 'HostNegotiation'
      }`}
    >
      <img
        src={role === VENDOR ? VendorNegotiation : HostNegotiation}
        className="booking-banner-bg"
        alt="Chat with vendor"
      />
      <MdClear
        size={35}
        color="white"
        className="close-banner cursor"
        onClick={closeModal}
      />
      <h2>
        <img src={EventIcon} alt="Welcome" width="40" />
        Welcome to your negotiation!
      </h2>
      <p>{role === VENDOR ? vendorText : hostText}</p>
    </EventBuilderBanner>
  )
}
