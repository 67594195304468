import React from 'react'

import { Booking } from '../../../types'
import { useAppSelector } from '../../../hooks'
import { offeringTypes } from '../../../common/constants'

import {
  PackageDefaultCard,
  getOfferingBasicField,
  guestInfoDisplay,
} from './bookingFunctions'

import { FreeGuests, PaidGuests, ExtraNights } from '.'

export const BookingAddons: React.FC<{
  booking: Booking
  editing?: boolean
}> = ({ booking, editing }) => {
  const { eventComponent } = booking
  const { offering, customTexts } = eventComponent
  const {
    availableAddons: { extraGuestAddon, extraKidAddon, extraFreeGuestAddon },
  } = useAppSelector(state => state.booking)

  const isRoom = offering.__typename === offeringTypes.roomOffering
  const basic = getOfferingBasicField(offering)

  const hasGuestAddons = extraGuestAddon || extraKidAddon || extraFreeGuestAddon
  const hasPaidGuestAddons = extraGuestAddon || extraKidAddon

  if (!hasGuestAddons && !hasPaidGuestAddons && !isRoom) {
    // To not render an empty card
    return <PackageDefaultCard component={eventComponent} />
  }

  const indication =
    customTexts?.guests_indication ??
    (isRoom
      ? `Enter legal name as it appears on your passport and check the +1 box if the other guest(s) are attending the event with you as your +1, then save. Do not check the +1 box for yourself.`
      : `Enter legal name as it appears on your passport then save.`)

  /*
   * For the moment, we only allow the guest to edit the free guests
   * if we allow the guest to edit the paid guests, we need to update the api as well in order to generate an extra payment
   * calculate new total price and update the booking
   * in the other hand, if the guest remove paid stuff, we need to refund and we can't do that yet
   */
  return (
    <div className="w-100 my-4 py-4 px-3 card">
      {isRoom && !editing && (
        <>
          <h4 className="text-left mb-3">
            Choose your stay{' '}
            <span className="fz-12 mb-3 tc-lgray">
              you can add extra nights before and after the event
            </span>
          </h4>
          <ExtraNights booking={booking} />
          <hr className="my-5" />
        </>
      )}
      {hasGuestAddons && (
        <>
          <h4 className="text-left mb-2">
            Guests Information {guestInfoDisplay(basic, isRoom)}
          </h4>
          <p className="fz-16">{indication}</p>
        </>
      )}

      {extraFreeGuestAddon && (
        <FreeGuests
          basic={basic}
          guestShort={booking.guest}
          showPlusOne={isRoom}
        />
      )}
      {hasPaidGuestAddons && !editing && (
        <>
          <hr className="my-5" />
          <h4 className="text-left mb-1">
            {customTexts?.paid_guests_title ?? 'Extra guests'}
          </h4>
          <PaidGuests booking={booking} showPlusOne={isRoom} />
        </>
      )}
    </div>
  )
}
