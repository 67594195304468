import React from 'react'
import toInteger from 'lodash/fp/toInteger'
import { IoMdBed } from 'react-icons/io'
import { FaRegMoneyBillAlt } from 'react-icons/fa'

import { FormatNumber } from '../../../components'
import { createOccupancyIcons } from '../../../pages/Locations'
import { RoomOffering } from '../../../types'
import squareFeetMeasurements from '../../../assets/images/icons/square-feet-measurements.png'
import WsquareFeetMeasurements from '../../../assets/images/icons/w-square-feet-measurements.png'

import { RoomDetailsContainer } from './style'

export const RoomDetails: React.FC<{
  data: RoomOffering
  className?: string
  whiteIcon?: boolean
}> = ({ data, className = '', whiteIcon }) => {
  const beds = data?.room?.beds || 0
  const occupancy = toInteger(data?.room?.occupancy) || 0
  const size = toInteger(data?.room?.size) || 0
  const roomPrice = data?.marketPrice || data?.price

  return (
    <RoomDetailsContainer className={className}>
      <h4 className="pl-1 m-0 mt-2 w-100">Details</h4>
      <div>
        <FaRegMoneyBillAlt size={20} color="#4a4a4a" />
        <span className="d-block tc-lgray">
          Price:{' '}
          {roomPrice === '0' ? 'upon request' : <FormatNumber n={roomPrice} />}
        </span>
      </div>
      {occupancy > 0 && (
        <div>
          {createOccupancyIcons(occupancy)}
          <span className="d-block tc-lgray">OCCUPANCY</span>
        </div>
      )}
      <div>
        <IoMdBed size={22} />
        {beds > 1 && <IoMdBed size={22} />}
        <span className="d-block tc-lgray">
          {beds} BED{beds > 1 ? 'S' : ''}
        </span>
      </div>
      <div className={size <= 0 ? 'd-none' : ''}>
        {whiteIcon ? (
          <img src={WsquareFeetMeasurements} alt="squareFeetMeasurements" />
        ) : (
          <img src={squareFeetMeasurements} alt="squareFeetMeasurements" />
        )}
        <span className="d-block tc-lgray">Size: {size} sq. ft.</span>
      </div>
    </RoomDetailsContainer>
  )
}
