import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { MdZoomIn, MdZoomOut } from 'react-icons/md'

import { ExpandGallery } from '../style'
import { getImages } from '../../../common'
import { offeringTypes } from '../../../common/constants'
import { FullSlider } from '../..'
import { ExpandedGallery, LocationOverview } from '..'
import {
  RoomOffering,
  Image,
  ILocationMetadata,
  IOfferingModal,
} from '../../../types'

import { OfferingModalDetails } from './OfferingModalDetails'

import { RoomDetails } from '.'

export const OfferingModal: React.FC<IOfferingModal> = ({
  show,
  setShowModal,
  data,
  guests,
  pricePerPerson,
  packagePrice,
  isMyEvent = false,
  optionalCTA,
  customizeComponent,
  customSlider,
  alias,
  hideDescription = false,
}) => {
  const [current, setCurrent] = useState(0)
  const [expanded, setExpanded] = useState(false)

  const images: Image[] = data?.images || []
  const imagesArray: string[] = getImages(images, '900x600')
  const metadata: ILocationMetadata = images[current]?.metadata

  const { roomOffering, packageOffering } = offeringTypes

  return (
    <Modal
      show={show}
      centered
      onHide={() => setShowModal(false)}
      className={`offering-modal ${expanded ? 'expanded' : ''}`}
    >
      <Modal.Header closeButton>
        <Modal.Title className="fwsb fz-24 pt-1">
          {customizeComponent || alias || data.name}
        </Modal.Title>
        <ExpandGallery
          className={`cursor ${
            (data.__typename === roomOffering || customSlider) && 'd-none'
          }`}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? <MdZoomOut size={22} /> : <MdZoomIn size={22} />}
          {expanded ? 'Exit Full Gallery' : 'Enlarge gallery'}
        </ExpandGallery>
      </Modal.Header>
      <Modal.Body className="p-0">
        {expanded && (
          <ExpandedGallery
            afterChange={setCurrent}
            current={current}
            data={images}
          />
        )}

        {!expanded && (
          <>
            {customSlider || (
              <>
                {imagesArray.length > 1 && (
                  <FullSlider
                    small
                    images={imagesArray}
                    afterChange={setCurrent}
                    current={current}
                    panel={
                      <div className="location-overview-container">
                        {data.__typename === roomOffering ? (
                          <RoomDetails
                            data={data as RoomOffering}
                            className="panel-style rooms"
                            whiteIcon
                          />
                        ) : (
                          <LocationOverview
                            className="panel-style packages"
                            data={metadata}
                          />
                        )}
                      </div>
                    }
                  />
                )}
                {imagesArray.length === 1 && (
                  <div
                    className="single-photo modal-image"
                    style={{ backgroundImage: `url(${imagesArray[0]})` }}
                  >
                    <div className="location-overview-container">
                      {data.__typename === roomOffering ? (
                        <RoomDetails
                          data={data as RoomOffering}
                          className="panel-style rooms"
                          whiteIcon
                        />
                      ) : (
                        <LocationOverview
                          className="panel-style packages"
                          data={metadata}
                        />
                      )}
                    </div>
                  </div>
                )}
              </>
            )}

            <OfferingModalDetails
              data={data}
              guests={guests}
              pricePerPerson={pricePerPerson}
              packagePrice={packagePrice}
              hideDescription={hideDescription}
            />

            {!(isMyEvent && data.__typename === packageOffering) && optionalCTA}
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}
