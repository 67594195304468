import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { IRSVPslider } from '../../../types'
import { getImageUrl } from '../../../common'
import { guestStatuses } from '../../../common/constants'

import { MainProviderImage } from './style'

import { EventComponentOffering, ValidationCTA } from '.'

export const RSVPslider: React.FC<IRSVPslider> = React.memo(
  ({ list, onChange, guest, coverSize }) => {
    const { provider } = list[0].offering
    const img = getImageUrl(provider.mainImage, '650x450')
    const { accepted } = guestStatuses
    return (
      <Swiper
        className="swiper-rsvp-slider"
        style={{ width: '100%' }}
        navigation
        spaceBetween={10}
        onSlideChange={swp => onChange(swp.activeIndex)}
      >
        <SwiperSlide>
          <MainProviderImage
            style={{ backgroundImage: `url(${img})` }}
            className="big-slide rounded-25-0-0-25"
          />
        </SwiperSlide>

        {list.map(el => (
          <SwiperSlide key={`slide-rooms-${el.id}`}>
            <EventComponentOffering
              className="mt-0 rounded-25-0-0-25"
              eventComponent={el}
              isMyEvent={false}
              big
              customSize={coverSize}
              externalValidation={
                guest?.status !== accepted ? (
                  <ValidationCTA type={el.offering.__typename} />
                ) : undefined
              }
              hideText
            />
          </SwiperSlide>
        ))}
      </Swiper>
    )
  }
)
