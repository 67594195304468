import React, { useState, useEffect } from 'react'
import set from 'lodash/fp/set'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import TextareaAutosize from 'react-textarea-autosize'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { MdSend } from 'react-icons/md'

import { errorAlert, getMoreData } from '../../../../common'
import { VENDOR } from '../../../../common/constants'
import {
  CREATE_NEGOTIATION_MESSAGE,
  GET_NEGOTIATION_MESSAGES,
  GET_NEGOTIATION_MESSAGES_VENDOR,
  NEGOTIATION_MESSAGES_SUBSCRIPTION,
} from '../../../../graphql'
import {
  Quote,
  IMessages,
  NegotiationMessage,
  PaginatedNegotiationMessages,
} from '../../../../types'
import { scrollToBottom } from '../../Builder/components/eventBuilderFunctions'

import { RenderMessages } from './RenderMessages'
import { MessagesContainer, MessagesInput, ChatContainer } from './style'

const SendMsg: React.FC<IMessages> = ({ eventId, providerId, path, role }) => {
  const QUERY =
    role === VENDOR ? GET_NEGOTIATION_MESSAGES_VENDOR : GET_NEGOTIATION_MESSAGES

  const [body, setBody] = useState('')
  const [firstMessagesLoad, setFirstMessagesLoad] = useState(true)
  const [sendMessage, { loading }] = useMutation(CREATE_NEGOTIATION_MESSAGE)

  const {
    data: messagesData,
    fetchMore,
    subscribeToMore,
  } = useQuery(QUERY, {
    variables: { eventId, providerId, cursor: null },
    fetchPolicy: 'network-only',
  })

  const messages: PaginatedNegotiationMessages = get(
    `${path}.messages`,
    messagesData
  )
  const quotes: Quote[] = getOr([], `${path}.quotes`, messagesData)

  const entries: NegotiationMessage[] = getOr([], 'entries', messages)
  const cursor = getOr('', 'cursor', messages) as string

  const handleSendMessage = () => {
    if (!loading && body !== '') {
      const variables = { eventId, providerId, input: { body } }
      sendMessage({ variables }).then(({ data }) => {
        if (data.createNegotiationMessage.errors.length > 0) {
          errorAlert(
            data.createNegotiationMessage.errors,
            'There was an error sending message'
          )
        } else {
          setBody('')
        }
      })
    }
  }

  useEffect(() => {
    // just to scroll to the bottom of the message container on the first load
    if (firstMessagesLoad && messages) {
      setFirstMessagesLoad(false)
      scrollToBottom()

      subscribeToMore({
        document: NEGOTIATION_MESSAGES_SUBSCRIPTION,
        variables: { eventId, providerId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev
          }

          const newData = subscriptionData.data.negotiationMessages
          const oldData = getOr([], `${path}.messages.entries`, prev)
          scrollToBottom()
          return set(`${path}.messages.entries`, [newData, ...oldData], prev)
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstMessagesLoad, messages, eventId, path, providerId])

  const loadMoreMessages = () => {
    if (!loading) {
      getMoreData({
        fetchMore,
        query: QUERY,
        variables: {
          eventId,
          providerId,
        },
        cursorToken: cursor,
        path: `${path}.messages`,
      })
    }
  }

  return (
    <ChatContainer>
      <MessagesContainer id="chat-with-vendor-container">
        {cursor && (
          <span
            className="mb-4 text-primary text-center d-block cursor"
            onClick={() => loadMoreMessages()}
          >
            Load more...
          </span>
        )}
        <RenderMessages messages={entries} quotes={quotes} />
      </MessagesContainer>
      <MessagesInput>
        <TextareaAutosize
          id="chat-reply"
          placeholder="Type your reply here..."
          onChange={ev => setBody(ev.currentTarget.value)}
          value={body}
          onKeyDown={ev => ev.key === 'Escape' && setBody('')}
        />
        <span
          className={body ? 'send-msg cursor shadow-sm' : 'd-none'}
          data-cy="send-reply-btn"
          onClick={() => {
            if (!loading) {
              handleSendMessage()
            }
          }}
        >
          <MdSend color="white" size={18} />
        </span>
      </MessagesInput>
    </ChatContainer>
  )
}

export default React.memo(SendMsg)
