import gql from 'graphql-tag'

export const ADD_USER = gql`
  mutation createCustomer(
    $input: CustomerInput!
    $token: String!
    $partnership: [PartnershipTrackingInput!]
  ) {
    createCustomer(input: $input, token: $token, partnership: $partnership) {
      result {
        id
        firstName
        lastName
      }
      errors {
        key
        message
      }
    }
  }
`

export const ADD_USER_NO_PASS = gql`
  mutation createCustomerWithoutPassword(
    $input: CustomerInput!
    $token: String!
    $partnership: [PartnershipTrackingInput!]
  ) {
    createCustomerWithoutPassword(
      input: $input
      token: $token
      partnership: $partnership
    ) {
      result {
        id
        firstName
        lastName
      }
      errors {
        key
        message
      }
    }
  }
`

export const ADD_USER_FROM_LANDING = gql`
  mutation createLandingPageCustomer(
    $input: LandingPageCustomerInput!
    $token: String!
    $partnership: [PartnershipTrackingInput!]
  ) {
    createLandingPageCustomer(
      input: $input
      token: $token
      partnership: $partnership
    ) {
      result {
        id
        firstName
        lastName
      }
      errors {
        key
        message
      }
    }
  }
`

export const CREATE_TIER = gql`
  mutation createTier($eventId: ID!, $input: TierInput!) {
    createTier(eventId: $eventId, input: $input) {
      result {
        id
        name
      }
      errors {
        key
        message
      }
    }
  }
`

export const UPDATE_TIER = gql`
  mutation updateTier($id: ID!, $input: TierInput!) {
    updateTier(id: $id, input: $input) {
      result {
        id
        name
      }
      errors {
        key
        message
      }
    }
  }
`

export const DELETE_TIER = gql`
  mutation deleteTier($id: ID!) {
    deleteTier(id: $id) {
      result {
        id
      }
      errors {
        key
        message
      }
    }
  }
`
