import React from 'react'
import Image from 'react-bootstrap/Image'
import { MdPerson } from 'react-icons/md'

import { Profile } from './style'

interface IUserProfile {
  url: string
  name: string
  className?: string
  style?: React.CSSProperties
}

export const DefaultUserPicture: React.FC<{
  size?: number
  className?: string
}> = React.memo(({ size = 24, className }) => (
  <Profile className={`default-user-profile ${className}`}>
    <MdPerson color="#4a4a4a" size={size} />
  </Profile>
))

export const UserPicture: React.FC<IUserProfile> = React.memo(
  ({ name, url, className, style }) => (
    <Image
      className={`user-profile ${className}`}
      style={
        style || {
          display: 'inline-block',
          height: '40px',
          verticalAlign: 'top',
          width: '40px',
        }
      }
      src={url}
      alt={name}
      roundedCircle
    />
  )
)
