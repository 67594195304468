import React from 'react'
import Container from 'react-bootstrap/Container'

import { CreateEventCard } from '../../../components/BannersModule/components/CreateEventCard'
import Zero from '../../../assets/images/zero/browser-listing.jpg'

export const GroundZero: React.FC = () => {
  return (
    <Container>
      <div className="pt-5 pr-3 text-right">
        <div className="d-inline-block" style={{ width: 'fit-content' }}>
          <CreateEventCard complete />
        </div>
      </div>
      <img
        data-cy="guide-image-gz"
        className="w-100 img-fluid"
        src={Zero}
        alt="Ground Zero"
      />
    </Container>
  )
}
