import React, { useState } from 'react'
import Swal from 'sweetalert2'
import flow from 'lodash/fp/flow'
import head from 'lodash/fp/head'
import filter from 'lodash/fp/filter'
import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'

import { CREATE_BOOKING } from '../../../graphql'
import { SignUpSignInForValidations } from '../../../components'
import { EventIcon, RoomIcon } from '../../../components/customIcons'
import { offeringTypes } from '../../../common/constants'
import {
  errorAlert,
  loaderAlert,
  useCurrentUser,
  useQueryParams,
} from '../../../common'
import {
  Booking,
  GuestBasic,
  BookingStatus,
  EventComponent,
  ISignInUpPreloadedData,
} from '../../../types'

interface ICTAbook {
  eventComponent: EventComponent
  className?: string
  guest?: GuestBasic
}

const buttonText = (type = '') => {
  const { roomOffering } = offeringTypes

  if (type === roomOffering) {
    return (
      <>
        <RoomIcon color="white" width="25px" height="25px" />
        Book
      </>
    )
  }
  return (
    <>
      <EventIcon color="white" width="25px" height="25px" />
      RSVP
    </>
  )
}

export const CTAbook: React.FC<ICTAbook> = ({
  eventComponent,
  className,
  guest,
  children,
}) => {
  const history = useHistory()
  const { previewpage } = useQueryParams()
  const { isAuthenticated } = useCurrentUser()
  const [registerModal, openRegisterModal] = useState(false)
  const [createBooking, { loading }] = useMutation(CREATE_BOOKING)
  const [preloadedData, setPreloadedData] = useState<ISignInUpPreloadedData>()
  const bookings = eventComponent?.booking
  const { offering, capacity, bookingCount } = eventComponent
  const { __typename: type } = offering
  const { Created } = BookingStatus

  const booking = flow(
    filter((b: Booking) => b.status === Created),
    head
  )(bookings)

  const handleCreateBooking = (ev: React.MouseEvent) => {
    ev.preventDefault()
    if (history.location.pathname.includes('rsvp-preview') || previewpage) {
      return
    }

    if (booking?.id) {
      history.push(`/booking/${booking.id}`)
      return
    }
    loaderAlert({ html: 'processing your booking' })

    const variables = {
      eventComponentId: eventComponent.id,
    }
    createBooking({ variables })
      .then(({ data }) => {
        Swal.close()
        const errors = data?.createBooking?.errors || []
        const result = data?.createBooking?.result

        if (errors.length > 0) {
          errorAlert(errors, 'There was an error booking this property')
        } else {
          history.push(`/booking/${result?.id}`)
        }
      })
      .catch(error => errorAlert([], error.message))
  }

  if (!preloadedData && guest) {
    setPreloadedData({
      email: guest.email,
      firstName: guest.firstName,
      lastName: guest.lastName,
    })
  }

  const availability = capacity - bookingCount
  if (availability <= 0) return <></>
  return (
    <>
      <SignUpSignInForValidations
        open={registerModal}
        onClose={() => openRegisterModal(false)}
        title="Sign up or sign in to book"
        text="You must be signed in to book"
        preloadedData={preloadedData}
      />

      <Button
        className={className}
        onClick={
          isAuthenticated ? handleCreateBooking : () => openRegisterModal(true)
        }
        disabled={loading}
      >
        {children || buttonText(type)}
      </Button>
    </>
  )
}
