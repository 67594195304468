import React from 'react'
import NumberFormat from 'react-number-format'
import { isValidNumber } from 'libphonenumber-js'
import { BsFillTelephoneFill } from 'react-icons/bs'

import { Maybe } from '../types'

export const FormatNumber: React.FC<{
  n: number | string
}> = React.memo(({ n }) => (
  <NumberFormat
    value={n}
    decimalScale={2}
    fixedDecimalScale
    displayType="text"
    thousandSeparator
    prefix="$"
    renderText={value => value}
  />
))

export const RenderPhone: React.FC<{ phone?: Maybe<string> }> = ({ phone }) => {
  // if phone number is not provided, return null
  if (!phone) return null

  // if phone number is not valid, return as is
  if (!isValidNumber(phone)) return <span>{phone}</span>

  // if phone number is valid, return as a clickable link
  return (
    <a href={`tel:${phone}`} className="text-decoration-none text-secondary">
      <BsFillTelephoneFill className="mr-1" size={16} /> {phone}
    </a>
  )
}
