import React, { useState } from 'react'
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button'
import Tooltip from 'react-bootstrap/Tooltip'
import Input from 'react-bootstrap/FormControl'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { useMutation } from '@apollo/react-hooks'
import {
  MdEdit,
  MdCancel,
  MdOutlineStarOutline,
  MdOutlineStar,
} from 'react-icons/md'

import { salmon } from '../../../styles/themeColors'
import { ICustomizeComponent } from '../../../types'
import { offeringTypes } from '../../../common/constants'
import { simpleAlert, loaderAlert } from '../../../common'
import { ALIAS_EVENT_COMPONENT } from '../../../graphql'

import { CustomizeComponentContainer } from './style'
import { useSetMainComponent } from './rsvpFunctions'

export const CustomizeComponent: React.FC<ICustomizeComponent> = ({
  isMyEvent,
  data,
  isMain = false,
  eventComponentId,
  eventComponentAlias,
}) => {
  const [alias, setAlias] = useState(eventComponentAlias || data.name)
  const [editing, setEditing] = useState(false)
  const [updateAlias, { loading }] = useMutation(ALIAS_EVENT_COMPONENT)
  const [setMain, loadingMain] = useSetMainComponent()
  const { roomOffering } = offeringTypes

  if (!isMyEvent || data.__typename === roomOffering) {
    return <>{alias}</>
  }

  const handleUpdateAlias = () => {
    if (loading) return
    loaderAlert({ html: 'updating...' })

    const variables = {
      id: eventComponentId,
      input: { alias },
    }
    updateAlias({ variables }).then(resp => {
      Swal.close()
      if (resp.data.updateEventComponent.errors.length > 0) {
        simpleAlert({
          html: 'it was not possible to update the alias of this package',
          icon: 'warning',
        })
      } else {
        setEditing(false)
      }
    })
  }

  const handleSetMain = () => {
    if (loadingMain || isMain) return
    loaderAlert({ html: 'updating...' })
    setMain(eventComponentId)
  }

  /**
   * I could not make an external component to reuse in the tooltips
   * TODO: investigate why the tooltip doesn't work when its imported
   */
  const delay = { show: 100, hide: 200 }
  return (
    <CustomizeComponentContainer>
      {editing && (
        <>
          <Input
            value={alias}
            onChange={e => setAlias(e.target.value)}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                handleUpdateAlias()
              }
            }}
          />
          <Button className="mx-2 cursor" onClick={() => handleUpdateAlias()}>
            Save
          </Button>
          <MdCancel className="cursor" onClick={() => setEditing(false)} />
        </>
      )}
      {!editing && (
        <>
          {alias}
          <OverlayTrigger
            delay={delay}
            overlay={
              <Tooltip id={`tooltip-editEC-${eventComponentId}`}>
                Rename your event for your guests to see
              </Tooltip>
            }
          >
            <MdEdit className="mx-2 cursor" onClick={() => setEditing(true)} />
          </OverlayTrigger>
        </>
      )}

      <span className={`make-it-main${isMain ? '' : ' cursor'}`}>
        <OverlayTrigger
          delay={delay}
          overlay={
            <Tooltip id={`tooltip-mainEC-${eventComponentId}`}>
              {isMain
                ? 'This will appear as your main event for guest invitations.  Selecting another main event will deselect this one.'
                : 'Make this your main event for priority positioning on guest invitations.  Selecting automatically deselects previous main event.'}
            </Tooltip>
          }
        >
          {isMain ? (
            <MdOutlineStar
              color={salmon}
              size={30}
              onClick={() => handleSetMain()}
            />
          ) : (
            <MdOutlineStarOutline
              color={salmon}
              size={30}
              onClick={() => handleSetMain()}
            />
          )}
        </OverlayTrigger>
      </span>
    </CustomizeComponentContainer>
  )
}
