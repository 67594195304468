import React, { useState, Suspense } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import get from 'lodash/fp/get'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { MyEvent } from '../../../types'
import { SimpleLoader } from '../../../components'
import { GET_MY_EVENT_INFO } from '../../../graphql'
import { Common404 } from '../../../components/Error404'

import {
  SettingsCard,
  RSVPSettingsPreview,
  InvitationPreview,
} from './components'

const BannersModule = React.lazy(
  () => import('../../../components/BannersModule/BannersModule')
)

export const Settings: React.FC = () => {
  const [version, setVersion] = useState('desktop')
  const [previewHelper, setPreviewHelper] = useState(0) // to refresh the previw after updating the event
  const { eventId } = useParams<{ eventId: string }>()
  const variables = { id: eventId }
  const { data, loading } = useQuery(GET_MY_EVENT_INFO, { variables })
  const event: MyEvent = get('me.myEvents[0]', data)

  if (loading) return <SimpleLoader />
  if (!event) return <Common404 text="Data not available" />

  return (
    <Container fluid key={`event-settings-${eventId}`}>
      <Helmet>
        <title>Kiss & Tell - Event Settings</title>
        <meta
          name="description"
          content="Customize your event settings and preview the changes in real time."
        />
      </Helmet>
      <Row>
        <Col sm={12} lg={5} className="setting-card-helper">
          <SettingsCard
            event={event}
            setPreviewHelper={setPreviewHelper}
            previewHelper={previewHelper}
          />
        </Col>
        <Col sm={12} lg={7}>
          <RSVPSettingsPreview
            key={`preview-${previewHelper}`}
            eventId={eventId}
            version={version}
            setVersion={setVersion}
          />
          <InvitationPreview
            key={`invitation-preview-${previewHelper}`}
            className="invitation-preview-container"
            event={event}
          />
        </Col>
      </Row>

      <Container as="section" className="mt-5">
        <h3 className="pb-2 fwl fz-35 text-center">
          Select your date by booking a venue or group stay!
        </h3>
        <Suspense fallback={<SimpleLoader />}>
          {/** In this view the user will always have events, so it's not necessary to use a query */}
          <BannersModule myEvents={1} text="Search Venues" />
        </Suspense>
      </Container>
    </Container>
  )
}
